import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useLocation, useNavigate, useParams } from "react-router";
import FormatDate from "../../masters/DateFormater";
import { Icon } from "../../../components/Component";
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

function SupplierPaymentsInvoiceReport() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const name = location.state?.name;

  const columns: any = [
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      fixed: "left",
      width: 200,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/supplier-payments-invoice-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 150,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 230,
    },
    {
      title: "GRN Date",
      dataIndex: "grnDate",
      key: "grnDate",
      width: 150,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Payment Due Date",
      dataIndex: "paymentDueDate",
      key: "paymentDueDate",
      width: 150,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Invoice Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstandingAmount",
      key: "outstandingAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Payment Days",
      dataIndex: "paymentDays",
      key: "paymentDays",
      width: 160,
      // render: (paymentDays: any) => <span>{paymentDays} days</span>,
      // render: (text: any, record: any) => {
      //   //  "flag": "overDue",
      //   const isHighlighted = record?.flag=="overDue";
      //   return (
      //     <div
      //       className="align-left"
      //       style={{
      //         color: isHighlighted ? "lightcoral" : "green",
      //         backgroundColor: isHighlighted ? "white" : "inherit",
      //         padding: "4px",
      //         borderRadius: "4px",
      //       }}
      //     >
      //       {isHighlighted?"OVERDUE BY ":"DUE IN"} {" "} {Number(text) + " DAYS"}
      //     </div>
      //   );
      // },
      render: (text: any, record: any) => {
        //  "flag": "overDue",
        const isHighlighted = record?.flag == "overDue";
        let placeholder = "";
        if (record?.flag == "overDue") {
          placeholder = "OVERDUE BY " + Number(text) + " DAYS";
        } else {
          if (record?.flag == "Paid") {
            placeholder = "PAID";
          } else {
            if (text == 0) {
              placeholder = "DUE TODAY";
            } else {
              placeholder = "DUE IN " + Number(text) + " DAYS";
            }
          }
        }
        return (
          <div
            className="align-left"
            style={{
              color: isHighlighted ? "lightcoral" : "green",
              backgroundColor: isHighlighted ? "white" : "inherit",
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {placeholder}
          </div>
        );
      },
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle={`${name} - Payments, Invoice Report`}
      moduleKey="supplierPaymentsInvoiceReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/supplier-payments-reports-list"
      extra={{
        id: id,
        tableScrollX: 1000,
      }}
    />
  );
}

export default SupplierPaymentsInvoiceReport;
