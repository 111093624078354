import React, { useEffect, useState } from "react";
import { useFormContext, SubmitHandler } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import AddNewMaterial from "./AddNewMaterial";
import { Table, Space } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Icon } from "../../../components/Component";
import moment from "moment";
import { useFormData } from "./FormDataContext"; // Import your context hook
import { Material, SelectOptions } from "./types"; // Import your types
import { nanoid } from "nanoid";
import { render } from "@testing-library/react";
import dayjs from "dayjs";
import { formatCurrency } from "../../../utils/Utils";

const BudgetMaterials: React.FC<{ next: () => void; prev: () => void }> = (
  props: any
) => {
  const { handleSubmit } = useFormContext();
  const { formData, updateFormData } = useFormData(); // Use the context to access formData and updateFormData
  const [dataSource, setDataSource] = useState<Material[]>(
    formData.materialsData || []
  ); // Initialize with context data
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMaterial, setCurrentMaterial] = useState<Material | null>(null);
  const [budgetHeader, setBudgetHeader] = useState<any>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const columns = [
    {
      title: "Budget Line",
      dataIndex: "budgetLineName",
      key: "budgetLineName",
      fixed: "left" as const,
      width: 120,
    },
    {
      title: "Budget Line Description",
      dataIndex: "lineDescription",
      key: "lineDescription",
      // fixed: 'left' as const,
      width: 200,
    },
    {
      title: "Budget Value",
      dataIndex: "budgetValue",
      key: "budgetValue",
      width: 150,
      render: (text: any) => {
        const value = parseFloat(text.replace(/,/g, ""));
        return isFinite(value) ? formatCurrency(value, "INR") : text;
      },
    },
    {
      title: "Planned Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 200,
      render: (text: any) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Planned End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text: any) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Bill Of Material (BOM)",
      dataIndex: "bom",
      key: "bom",
    },
    {
      title: "BOM Allocation",
      dataIndex: "allocation",
      key: "allocation",
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right" as const,
      width: 100,
      render: (_: any, record: Material) => (
        <Space size="middle">
          <a onClick={() => openModal(record)}>
            <EditOutlined />
          </a>
          <a onClick={() => handleDelete(record.key)} className="delete">
            <DeleteOutlined />
          </a>
        </Space>
      ),
    },
  ];

  const submitForm: SubmitHandler<any> = (data) => {
    // const submissionData = {
    //   ...formData,
    //   budgetLines: dataSource.map((material, index) => ({
    //     budgetLineName: formData.materialsData.budgetLineName,
    //     lineDescription: formData.materialsData.lineDescription,
    //     budgetValue:formData.materialsData.budgetValue,
    //     currencyCode: formData.materialsData.currencyCode,
    //     startDate: formData.materialsData.startDate,
    //     endDate: formData.materialsData.endDate,
    //     bom: formData.materialsData.bom,
    //     allocation: formData.materialsData.allocation,
    //     budgetId: nanoid(4),
    //     budgetLineId: formData.materialsData.budgetLineId, // Added delivery address ID
    //     bomLines: formData.materialsData.bomLines, // Added delivery address name
    //   })),
    //   docKeys: dataSource.map((material, index) => ({
    //     fileIndex: index + 1,
    //     module: "Module", // Placeholder, modify as needed
    //     moduleFileType: "FileType", // Placeholder, modify as needed
    //     level: "LINE_LEVEL",
    //     file: material.file, // Include the file itself
    //     fileName: material.fileName // Include the file name
    //   })),
    // };

    // updateFormData('budgetLines', submissionData.budgetLines);
    // updateFormData('docKeys', submissionData.docKeys);

    console.log("Materials Data:", formData);
    // return
    props.next();
  };

  useEffect(() => {
    console.log("Previous Data:", formData);
    setBudgetHeader(formData.budgetHeader);
  }, [formData]);

  useEffect(() => {
    setDataSource(formData.materialsData || []);
  }, [formData.materialsData]);

  const openModal = (material?: Material) => {
    setCurrentMaterial(material ?? null);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setCurrentMaterial(null); // Reset currentMaterial to null
    setIsModalVisible(false);
  };

  const handleAddOrEditMaterial = (material: Material) => {
    console.log("Added/Edited Material:", material);
    let updatedData;
    if (currentMaterial) {
      // Edit mode: Update existing material
      updatedData = dataSource.map((item) =>
        item.key === currentMaterial.key
          ? { ...material, key: currentMaterial.key }
          : item
      );
    } else {
      // Add mode: Add new material
      updatedData = [...dataSource, { ...material, key: `${Date.now()}` }];
    }
    setDataSource(updatedData);
    updateFormData("materialsData", updatedData); // Update context data
    closeModal();
  };

  const handleDelete = (key: string) => {
    const updatedData = dataSource.filter((item) => item.key !== key);
    setDataSource(updatedData);
    updateFormData("materialsData", updatedData); // Update context data
  };

  const handleExpand = (expanded: boolean, record: any) => {
    console.log("record", record, expanded);
    if (expanded) {
      // Add the record's key to the expandedRowKeys array
      setExpandedRowKeys((prevKeys) => [...prevKeys, record.key]);
    } else {
      // Remove the record's key from the expandedRowKeys array
      setExpandedRowKeys((prevKeys) =>
        prevKeys.filter((key) => key !== record.key)
      );
    }
  };

  const getFileIconType = (fileName: string) => {
    const extension = fileName.split(".").pop();
    switch (extension) {
      case "pdf":
        return "file-pdf";
      case "doc":
      case "docx":
        return "file-word";
      case "xls":
      case "xlsx":
        return "file-excel";
      case "jpg":
      case "jpeg":
      case "png":
        return "file-image";
      default:
        return "file";
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <div style={{ overflow: "auto" }}>
          <Table
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: "max-content" }} // Adjust this as necessary
            expandable={{
              expandedRowRender: (record: any) => {
                if (record.bom == "Y") {
                  return (
                    <div>
                      <div>
                        <p style={{ margin: 0 }}>
                          <strong>BOM Lines</strong>
                        </p>
                        <div className="mt-3">
                          <Table
                            className="customTable"
                            columns={[
                              {
                                title: "Category",
                                dataIndex: "categoryName",
                                key: "categoryName",
                                fixed: "left" as const,
                                width: 130,
                              },
                              {
                                title: "Material Name",
                                dataIndex: "materialName",
                                key: "materialName",
                                fixed: "left" as const,
                                width: 130,
                              },
                              {
                                title: "Qty",
                                dataIndex: "quantity",
                                key: "quantity",
                                fixed: "left" as const,
                                width: 130,
                              },
                              {
                                title: "UOM",
                                dataIndex: "uomName",
                                key: "uomName",
                                width: 130,
                              },
                              {
                                title: "Allocation",
                                dataIndex: "allocated",
                                key: "allocated",
                                width: 130,
                                render: (text: any) => {
                                  console.log("record",record)
                                  if (record.allocation === "value") {
                                    const value = parseFloat(
                                      text.toString().replace(/,/g, "")
                                    );
            
                                    // Check if the parsed value is a valid number
                                    if (!isNaN(value)) {
                                      // Format the value as currency
                                      return formatCurrency(value, "INR");
                                    } else {
                                      return text; // Return original value if invalid
                                    }
                                  } else {
                                    return text + "%";
                                  }
                                },
                              },
                            ]}
                            dataSource={record.bomLines?.map((item: any) => ({
                              ...item,
                              key: item?.id,
                            }))}
                            scroll={{ x: 1000 }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              },
              expandedRowKeys,
              onExpand: handleExpand,
              rowExpandable: (record: any) => record.bom === "Y", // Conditionally show expandable icon
            }}
            bordered
            size="small" // Making the table compact
            pagination={false}
            locale={{
              emptyText:
                "Please click on Add New Budget Line to add Budget lines.",
            }}
          />
          <span
            onClick={() => {
              openModal();
            }}
            style={{
              cursor: "pointer",
              marginTop: 10,
              display: "block",
              fontSize: 14,
            }}
          >
            <Icon name="plus-round"></Icon> &nbsp; Add New Budget Line
          </span>
        </div>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button
                title={dataSource.length === 0 ? "Please add materials" : ""}
                disabled={dataSource.length === 0}
                color="primary"
                type="submit"
              >
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
      <AddNewMaterial
        modal={isModalVisible}
        closeModal={closeModal}
        addRow={handleAddOrEditMaterial}
        currentMaterial={currentMaterial}
        deliveryAddressLevel={budgetHeader?.deliveryLevelType}
        budgetStartDate={budgetHeader?.startDate}
        budgetEndDate={budgetHeader?.endDate}
      />
    </>
  );
};

export default React.memo(BudgetMaterials);
