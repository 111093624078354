import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useLocation, useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import FormatDate from "../../masters/DateFormater";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

function InvoiceDetailsForPaymentsReport() {
   const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const name = location.state?.name;
  const columns: any = [
    {
      key: "orderId",
      title: "Order #",
      dataIndex: "orderId",
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
                state: {
                  previousPath: `/invoice-details-for-payments-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/invoice-details-for-payments-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    }, 
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 150,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      width: 150,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    // {
    //   title: "Outstanding Amount",
    //   dataIndex: "outstandingAmount",
    //   key: "outstandingAmount",
    //   render: (text: any, record: any) => (
    //     <div style={{ textAlign: "right" }}>
    //       <Tooltip title={formatCurrency(text?.toString(),record?.currencyCode)}>
    //         {formatCurrency2(text?.toString(), record?.currencyCode)}
    //         <Icon name="info" style={{ marginLeft: 8, fontSize: 12 }} />
    //       </Tooltip>
    //     </div>
    //   ),
    // },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
 
  ];
  return (
    <DefaultReportTemplate
      pageTitle={`Transaction # : ${name} , Invoice Details` }
      moduleKey="invoiceDetailsForPaymentsReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/payment-reports-list"
      extra={{
        id: id,
        tableScrollX:1000
      }}
    />
  );
}

export default InvoiceDetailsForPaymentsReport