import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Badge, ModalBody, Spinner } from "reactstrap";
import Head from "../../../layout/head/Head";
import { Block, Icon } from "../../../components/Component";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import {
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  TableColumnsType,
  Descriptions,
  List,
  Modal,
  Timeline,
  Popover,
  Empty,
} from "antd";
import FormatDate from "../../masters/DateFormater";
import supplierApis from "../../../api/master/supplier";
import costCenterApis from "../../../api/master/costCenter";
import sites from "../../../api/master/sites";
import GeneralLedgerApis from "../../../api/master/generalLedger";
import departments from "../../../api/master/departments";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import adminOrgApis from "../../../api/master/adminOrg";
import { PaperClipOutlined, DownloadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderCreation from "../../../api/purchase-orders/pruchaseOrderCreation";
import moment from "moment";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router";
import rolesApi from "../../../api/master/roles";
import dayjs from "dayjs";
import usersApis from "../../../api/master/users";
import purchaseRequestApis from "../../../api/master/purchaseRequest";
import { set } from "date-fns";
import misc from "../../../api/misc/dropDowns";

interface SelectOptions {
  label: string;
  value: string;
}

const { Panel } = AntCollapse;
const PurchaseRequest_Preview: React.FC = (props: any) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableDraft, setDisableDraft] = useState(false);
  const navigate = useNavigate();
  const { watch, register } = useFormContext();
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const { control, handleSubmit } = useForm();
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [usersOptions, setUsersOptions] = useState<SelectOptions[]>([]);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const previousFormData = watch();
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [roles, setRoles] = useState<any>([]);
  const [submitData, setSubmitData] = useState<any>("SUBMIT");
  const [tab, setTab] = useState("1");
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    // {
    //   ...Table.EXPAND_COLUMN,
    //   fixed: "left",
    // },
    // {
    //   title: "#",
    //   key: "#",
    //   width: 50,
    //   fixed: "left",
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "Material Category",
      dataIndex: "categoryLabel",
      key: "categoryLabel",
      width: 200,
      fixed: "left",
    },
    {
      title: "Material Name",
      dataIndex: "materialLabel",
      key: "materialLabel",
      width: 200,
      fixed: "left",
    },
    {
        title: 'Specification',
        dataIndex: 'specification',
        key: 'specification',
        width: 200,
      },
    {
      title: "Req Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text, record) => `${text} ${record?.uomLabel}`,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 150,
    },
    // {
    //     title: "Description",
    //     dataIndex: "description",
    //     key: "description",
    //     width: 150,
    // }
  ]);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

   useEffect(() => {
    if (previousFormData.siteId) {
      fetchUsers(previousFormData.siteId);
    }
  },[]);

  const fetchUsers = async (siteId:any) => {
    try {
      const data = await misc.fetchSiteUsers(siteId);
      if (data) {
        console.log("Data", data);
        let usersOptions = data?.data?.map((item: any) => ({
          label: item.userName,
          value: item.userId.toString(),
        }));
        setUsersOptions(usersOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        let deliveryAddressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApprovalHierarchyUsersByModuleId = async (moduleID: number) => {
    try {
      const res = await rolesApi.fetchHierarchyUsersByModuleId(
        moduleID,
        Number(previousFormData.siteId)
      );
      if (!res) return;
      const { data, status } = res;
      if (status) {
        setRoles(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const moduleID = res?.data?.find(
        (x: any) => x.moduleName == "Purchase Request"
      )?.moduleID;
      console.log(moduleID);
      await fetchApprovalHierarchyUsersByModuleId(moduleID);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    console.log("previousFormData", previousFormData);
    fetchModuleConfig();
    fetchDeliveryAddress();
    fetchSites();
  }, []);

  const ShowAddressPopOver = ({ id }: any) => {
    if (!id) {
      return <></>;
    }
    let address = deliveryAddressList?.find((item: any) => item?.id == id);
    if (!address) {
      return <></>;
    }
    return (
      <ul>
        <li>
          <strong style={{ color: "#2263b3" }}>
            {address?.deliveryLocationName}
          </strong>
        </li>
        <li>{address?.addressLine1}</li>
        <li>{address?.addressLine2}</li>
        <li>
          {address?.city}, {address?.stateName}, {address?.countryName} -{" "}
          {address?.pincode}
        </li>
      </ul>
    );
  };

  const ApprovalTimelineContent = () => {
    const approvalsData = JSON.parse(previousFormData?.approvalsData);
    if (
      !approvalsData ||
      approvalsData?.data?.length === 0 ||
      previousFormData?.approvalRequired == "false"
    )
      return <Badge color="outline-danger">No</Badge>;
    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.data?.map((approval: any, index: number) => {
              const Role = roles.find(
                (role: any) => role?.roleID == approval?.roleId
              );
              return (
                <Timeline.Item key={index}>
                  {
                    Role?.employees?.find((e: any) => e?.id == approval?.userId)
                      ?.name
                  }
                  <div className="designation">{Role?.roleName}</div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Yes
        </span>
      </Popover>
    );
  };

  const onSubmit = (status: string) => async (data: any) => {
    if (status == "Draft") {
      console.log("DRAFT");
      setDisableDraft(true);
    } else if (status == "SUBMIT") {
      console.log("SUBMIT");
      setDisableSubmit(true);
    }
    const formData: any = new FormData();
    let prData: any = {
      ...previousFormData,
    };
    console.log("prData", prData);
    const prHeader = {
      prSequence: prData.purchaseRequestId,
      referenceIndentNumber: prData.referenceIndentNumber,
      indentDate: dayjs(prData.indentDate).format("YYYY-MM-DD"),
      siteId: Number(prData.siteId),
      requestedBy: Number(prData.requestedBy),
      needByDate: dayjs(prData.needByDate).format("YYYY-MM-DD"),
      deliveryAddressId: Number(prData.deliveryAddressId),
      contractorId: Number(prData.contractorId),
      status: status,
    };
    const prLines = prData?.items?.map((item: any) => {
      return {
        categoryId: Number(item.materialCategory),
        categoryName: item.categoryLabel,
        materialId: Number(item.materialName),
        materialName: item.materialLabel,
        // description: item.description,
        quantity: Number(item.quantity),
        specification:item.specification,
        uomId: Number(item.uomId),
        remarks: item.remarks,
      };
    });
    const prApprovals = JSON.parse(prData?.approvalsData);

    if (prApprovals?.data?.length > 0 && prData.approvalRequired) {
      formData.append("prApprovals", JSON.stringify(prApprovals?.data));
    } else {
      formData.append("prApprovals", JSON.stringify([]));
    }

    formData.append("prLines", JSON.stringify(prLines));

    formData.append("prHeader", JSON.stringify(prHeader));
    postPR(formData);
  };

  const postPR = async (data: any) => {
    console.log("data", data);
    // return
    try {
      const api: any = await purchaseRequestApis.purchaseRequestCreation(data);
      const { status, message, response } = api;
      if (status) {
        Swal.fire("Created!", response.prSequence, "success");
        navigate(`/view-purchase-requests`);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
    setDisableSubmit(false);
    setDisableDraft(false);
  };

  return (
    <>
      <Head title="New - Purchase Request " />
      <div className="content clearfix">
        <form>
          <AntCollapse defaultActiveKey={["1", "2", "3", "4", "5"]}>
            <Panel header="General Information" key="1">
              {/* <div className="tab-content mt-0"> */}
              {/* <div className={`tab-pane${tab === "1" && " active"}`}> */}
              <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                <div className="w-30 border-end ">
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-45">
                      Reference Indent Number
                    </div>
                    <div className="data-value w-55 ">
                      {previousFormData.referenceIndentNumber}
                    </div>
                  </div>
                  <div className="data-col align-center pt-1">
                    <div className="data-label w-45">Indent Date</div>
                    <div className="data-value w-55">
                      {dayjs(previousFormData?.indentDate).format(
                        "DD-MMM-YYYY"
                      ) || "--"}
                    </div>
                  </div>
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-45">Site</div>
                    <div className="data-value w-55 ">
                      {
                        sitesOptions.find(
                          (item) => item.value == previousFormData.siteId
                        )?.label
                      }
                    </div>
                  </div>
                </div>
                <div className="w-30 border-end paddingLeft10  ">
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-30">Requested By</div>
                    <div className="data-value w-70 ">
                      {
                        usersOptions.find(
                          (item) => item.value == previousFormData.requestedBy
                        )?.label
                      }
                    </div>
                  </div>
                  <div className="data-col align-center pt-1">
                    <div className="data-label w-30">Need By Date</div>
                    <div className="data-value w-70">
                      {dayjs(previousFormData?.needByDate).format(
                        "DD-MMM-YYYY"
                      ) || "--"}
                    </div>
                  </div>
                  <div className="data-col align-center pt-1">
                    <div className="data-label w-30">Approvals</div>
                    <div className="data-value w-70">
                      <ApprovalTimelineContent />
                    </div>
                  </div>
                </div>
                <div className="w-30 paddingLeft10">
                  <div className=" pt-1">
                    <div className="data-label w-9 mb-1">Delivery Address</div>
                    <div className="data-value w-90">
                      <ShowAddressPopOver
                        id={previousFormData?.deliveryAddressId}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              {/* </div> */}
              {/* </div> */}
            </Panel>
            <Panel header="Material Information" key="2">
              <div className="table-responsive poTable">
                <div
                  style={{
                    width: screenSize - 400 + "px",
                    margin: "auto",
                  }}
                >
                  {columns && (
                    <Table
                      className="customTable"
                      columns={columns}
                      dataSource={previousFormData?.items}
                      scroll={{ x: 1000 }}
                      bordered
                      size="small"
                      pagination={false}
                    />
                  )}
                </div>
              </div>
            </Panel>
          </AntCollapse>
          <div className="actions clearfix">
            <ul>
              <li>
                {disableDraft ? (
                  <Button color="secondary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => {
                      setSubmitData("DRAFT");
                      setDisableDraft(true);
                      handleSubmit(onSubmit("DRAFT"))(); // Trigger form submission
                    }}
                  >
                    Save Draft
                  </Button>
                )}
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={() => {
                    props.prev();
                  }}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
              <li>
                {disableSubmit ? (
                  <Button color="primary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() => {
                      setSubmitData("SUBMIT"); // Set submitData to "SUBMITTED"
                      handleSubmit(onSubmit("SUBMIT"))(); // Trigger form submission
                    }}
                  >
                    Submit
                  </Button>
                )}
              </li>
            </ul>
          </div>
        </form>
      </div>
    </>
  );
};

export default PurchaseRequest_Preview;
