import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TooltipComponent } from "../../components/Component";
import GooglePlacesAutocomplete from "react-google-autocomplete";
import { set } from "date-fns";
const APIKEY = process.env.REACT_APP_GOOGLE_API;
const FormAutoComplete = ({
  name,
  label,
  required = false,
  placeholder = "Enter a location",
  onPlaceSelected = (value) => {},
  latitude = null,
  longitude = null,
  tooltipText,
}) => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    register(name, {
      required: required ? "This field is required" : undefined,
    });
  }, [register, name, required]);
  const fetchAddressFromLatLng = async (lat, lng) => {
    const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${APIKEY}`;
    try {
      const response = await fetch(geocodingApiUrl);
      const data = await response.json();
      if (data.status === "OK" && data.results.length > 0) {
        const formattedAddress = data.results[0].formatted_address;
        setValue(name, formattedAddress); // Update the form field with the address
      } else {
        setValue(name, "");
        console.error("Geocoding API error: ", data.status);
      }
    } catch (error) {
      setValue(name, "");
      console.error("Error fetching address: ", error);
    }
  };
  // Call fetchAddressFromLatLng when latitude and longitude are provided
  useEffect(() => {
    if (latitude && longitude) {
      fetchAddressFromLatLng(latitude, longitude);
    }
  }, [latitude, longitude]);
  const errorMessage = getErrorMessage(errors, name);
  return (
    <div className="form-group">
      <div className="form-label-wrapper">
        <label className="form-label" htmlFor={name}>
          {label}
          {required && <span className="mandatory">*</span>}
        </label>
      </div>
      <div className="form-control-wrap">
        <div className="form-control-select">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <GooglePlacesAutocomplete
                {...field}
                apiKey={APIKEY} // replace with your actual Google API key
                placeholder={placeholder}
                className="form-control"
                style={{ width: "100%", height: "38px" }}
                onPlaceSelected={(place) => {
                  field.onChange(place.formatted_address); // Updates form field with selected address
                  if (onPlaceSelected) {
                    onPlaceSelected(place); // Callback with the place data
                  }
                }}
                options={{
                  componentRestrictions: { country: "IN" }, // Restrict results to India, modify as needed
                  types: [],
                }}
              />
            )}
          />
          {errorMessage && <p className="mandatory">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};
function getErrorMessage(errors, fieldName) {
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean);
  let currentError = errors;
  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part];
    } else {
      return undefined;
    }
  }
  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }
  return undefined;
}
export default FormAutoComplete;