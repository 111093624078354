import React, { useEffect } from "react";
import {
  useFormContext,
  Controller,
  FieldValues,
  FieldErrors,
} from "react-hook-form";
import { TooltipComponent } from "../../components/Component";
import { Select } from "antd";
import { Spinner } from "reactstrap";

interface SelectOption {
  value: any;
  label: string;
}

interface FormSelectProps {
  name: string;
  label: any;
  optionLabel?: string;
  hideRequiredMark?: boolean;
  optionLabelWidth?: any;
  options: SelectOption[];
  required?: any;
  placeholder?: string;
  customValidation?: (value: string) => boolean | string;
  maxLength?: number;
  defaultValue?: any;
  disabled?: boolean;
  onChange?: (value: string) => void;
  dropdownRender?: (props: any) => JSX.Element;
  tooltipText?: string;
  popOverOptions?: any;
  showSearch?: boolean;
  isLoading?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  label,
  options,
  optionLabel,
  hideRequiredMark = false,
  optionLabelWidth = "34px",
  required = false,
  placeholder = "-Select-",
  customValidation,
  maxLength,
  defaultValue,
  disabled,
  onChange,
  tooltipText,
  popOverOptions,
  dropdownRender,
  showSearch = true,
  isLoading = false,
}) => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register(name, {
      required: required ? "This field is required" : undefined,
      maxLength: maxLength
        ? {
            value: maxLength,
            message: `Maximum length is ${maxLength} characters`,
          }
        : undefined,
      validate: customValidation
        ? (value: string) => customValidation(value) || "Invalid value"
        : undefined,
    });
  }, [register, name, required, maxLength, customValidation]);

  const errorMessage = getErrorMessage(errors, name);

  return (
    <div className={`form-group ${optionLabel ? "mb-1" : ""}`}>
      <div className="form-label-wrapper">
        {label && (
          <div className="form-label-wrapper">
            <label className="form-label" htmlFor={name}>
              {label}
              {required && !hideRequiredMark && (
                <span className="mandatory">*</span>
              )}
            </label>
            {popOverOptions}
            {tooltipText && (
              <TooltipComponent
                iconClass="card-hint"
                icon="help-fill"
                direction="left"
                id={`${name}-tooltip`}
                text={tooltipText}
                containerClassName={""}
              />
            )}
          </div>
        )}
      </div>
      <div className="form-control-wrap">
        <div
          className={`form-control-select ${
            optionLabel ? "d-flex align-items-center" : ""
          }`}
        >
          {optionLabel && (
            <label
              className={`form-label`}
              style={{ minWidth: optionLabelWidth }}
              htmlFor={name}
            >
              {optionLabel}
            </label>
          )}
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={options}
                value={options?.find((option) => option?.value == field?.value)}
                onChange={(selectedOption: any) => {
                  field.onChange(selectedOption);
                  if (!selectedOption) {
                    setValue(name, null);
                  }
                  if (onChange) {
                    onChange(selectedOption);
                  }
                }}
                style={{ width: "100%", height: "38px" }}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
                showSearch={showSearch}
                allowClear={!required}
                dropdownRender={isLoading?()=>{
                  return <div className="d-flex flex-row justify-content-center align-items-center my-5">
                    <Spinner size="lg" color="light" />
                  </div>
                }:dropdownRender}
                filterOption={(input: any, option: any) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            )}
          />
          {errorMessage && !optionLabel && (
            <p className="mandatory">{errorMessage}</p>
          )}
        </div>
        {errorMessage && optionLabel && (
          <p className="mandatory">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

function getErrorMessage<T extends FieldValues>(
  errors: FieldErrors<T>,
  fieldName: string
): string | undefined {
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean);
  let currentError: any = errors;

  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part];
    } else {
      return undefined;
    }
  }

  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }

  return undefined;
}

export default FormSelect;
