import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import usersApis from "../../../api/master/users";
import sites from "../../../api/master/sites";
import FormAutoComplete from "../../form-components/SelectGeoLocation";

interface SelectOptions {
  label: string;
  value: string;
}

const GeneralInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);
  const [location, setLocation] = useState({ lat: null, lng: null });
  const selectedCountry = watch("country");
  const selectedState = watch("state");

  const submitForm = (data: any) => {
    console.log("General Info Data:", { ...data, location });
    props.next();
  };

  const getDistricts = async (stateID: any) => {
    try {
      const districts = await sites.getDistrictsByState(stateID);
      if (districts.status) {
        const transformedData = districts.data.map((item: any) => ({
          value: item.districtId.toString(),
          label: item.districtName,
        }));
        setDistrictOptions(transformedData);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const onStateChange = (e: any) => {
    getDistricts(e);
  };

  const fetchCountrys = async (id?: string) => {
    try {
      const data = await usersApis.getCountrys();
      const countryOptions = data.data.map((country: any) => ({
        label: country.countryName,
        value: country.countryId.toString(),
      }));
      setCountries(countryOptions);
      if (id) {
        const country = countryOptions.find(
          (country: SelectOptions) => country.value === id.toString()
        );
        if (!country) return;
        setValue("country", id);
      }
    } catch (error) {}
  };

  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) return;
        setValue("state", state.value);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCountrys();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetchStatesByCountry(selectedCountry);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      getDistricts(selectedState);
    }
  }, [selectedState]);

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="referenceID"
              label="Site ID"
              required={true}
              type="text"
              pattern={/^[a-zA-Z0-9./_-]+$/}
              maxLength={30}
              placeholder="Enter Site ID"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="entityName"
              label="Entity Name"
              required={true}
              type="text"
              placeholder="Enter Entity"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="name"
              label="Site Name"
              required={true}
              type="text"
              minLength={2}
              maxLength={30}
              placeholder="Enter Site name"
            />
          </Col>
          <span style={{ display: "none" }}>
            <FormInput name="latitude" label="" type="text" />
            <FormInput name="longitude" label="" type="text" />
          </span>
          <Col lg="6">
            <FormAutoComplete
              name="siteAddress"
              label="Select Location"
              required={true}
              placeholder="Search for the site address"
              onPlaceSelected={(place) => {
                console.log("Selected place:", place);
                if (place.geometry) {
                  const { lat, lng }: any = place.geometry.location;
                  setValue("latitude", lat());
                  setValue("longitude", lng());
                  console.log("Latitude:", lat(), "Longitude:", lng());
                }
              }}
              tooltipText="Search and select the site address from Google Places."
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="country"
              label="Country"
              options={countries}
              required={true}
              placeholder="Select Country"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="state"
              label="State"
              options={states}
              required={true}
              placeholder="Select State"
              onChange={(ev) => onStateChange(ev)}
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="district"
              label="District"
              options={districtOptions}
              required={true}
              placeholder="Select District"
            />
          </Col>
          <Col lg="3">
            <FormInput
              name="city"
              label="City/Town"
              required={true}
              placeholder="Enter City"
              type="text"
            />
          </Col>
          <Col lg="3">
            <FormInput
              name="pincode"
              label="Zip/Pincode"
              required={true}
              type="text"
              pattern={/^\d+$/} // Allows only digits
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
              }}
              placeholder="Enter Zip or pin code"
            />
          </Col>
          <Col lg="3">
            <FormInput
              name="siteAddressLine1"
              label="Address Line 1"
              required={true}
              placeholder="Enter Site Address"
              type="text"
            />
          </Col>
          <Col lg="3">
            <FormInput
              name="siteAddressLine2"
              label="Address Line 2"
              // required={true}
              placeholder="Enter Address Line 2"
              type="text"
            />
          </Col>
          <Col lg="3">
            <FormInput
              name="gstin"
              label="GSTIN"
              required={true}
              type="text"
              placeholder="Enter GSTIN"
              pattern={
                /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
              }
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default React.memo(GeneralInformation);
