import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useLocation, useNavigate } from "react-router";
import FormatDate from "../../masters/DateFormater";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";


function PaymentsAgingReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      fixed: "left",
      width: 200,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 150,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/payment-aging-reports-list`,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 150,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Payment Due Date",
      dataIndex: "paymentDueDate",
      key: "paymentDueDate",
      width: 150,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "GRN Date",
      dataIndex: "grnDate",
      key: "grnDate",
      width: 150,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Payment Days",
      dataIndex: "paymentDays",
      key: "paymentDays",
      width:160,
      // render: (paymentDays: any) => <span>{paymentDays} days</span>,
      // render: (text: any, record: any) => {
      //   //  "flag": "overDue",
      //   const isHighlighted = record?.flag=="overDue";
      //   return (
      //     <div
      //       className="align-left"
      //       style={{
      //         color: isHighlighted ? "lightcoral" : "green", 
      //         backgroundColor: isHighlighted ? "white" : "inherit",
      //         padding: "4px",
      //         borderRadius: "4px",
      //       }}
      //     >
      //       {isHighlighted?"OVERDUE BY ":"DUE IN"} {" "} {Number(record?.aging) + " DAYS"}
      //     </div>
      //   );
      // },
      render: (text: any, record: any) => {
        //  "flag": "overDue",
        const isHighlighted = record?.flag == "overDue";
        let placeholder=""
        if(record?.flag == "overDue"){
          placeholder="OVERDUE BY "+Number(record?.aging)+" DAYS"
        }else{
          if(record?.aging == 0){
            placeholder="DUE TODAY"
          }else{
            placeholder="DUE IN "+Number(record?.aging)+" DAYS"
          }
                 
        }
        return (
          <div
            className="align-left"
            style={{
              color: isHighlighted ? "lightcoral" : "green",
              backgroundColor: isHighlighted ? "white" : "inherit",
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {placeholder}
          </div>
        );
      },
    },
    // {
    //   title: "Aging Days",
    //   dataIndex: "aging",
    //   key: "aging",
    //   width: 100,
    //   render:(aging:any)=><span>{aging} days</span>
    // },
    // {
    //     title:"Due Type",
    //     dataIndex:"dueType",
    //     key:"dueType",
    //     width:150
    // },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstandingAmount",
      key: "outstandingAmount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Payments Aging Report"
      moduleKey="paymentsAgingReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
        aging: false,
      }}
      previousPath="/view-accounts-payable-reports"
      extra={{
        tableScrollX: 1000,
      }}
    />
  );
}

export default PaymentsAgingReport;