import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import dayjs from "dayjs";
import { useNavigate, useParams } from 'react-router';
import FormatDate from "../../masters/DateFormater";

function CommittedStockReport() {
  const navigate = useNavigate();
  const { id, historyId } = useParams();
  console.log("id", id);
  const columns: any = [
    {
      title: "Site",
      key: "siteName",
      dataIndex: "siteName",
      width: 100,
    },
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 100,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 100,
    },
    // {
    //   title: "UOM",
    //   key: "primaryUomName",
    //   dataIndex: "primaryUomName",
    //   width: 100,
    // },
    {
      title: "Issue Number",
      key: "issueNumber",
      dataIndex: "issueNumber",
      width: 100,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            // onClick={() => {
            //   navigate(`/view-invoice/${record.invoiceNumber}`);
            // }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Created Date",
      key: "createdAt",
      dataIndex: "createdAt",
      width: 150,
      render: (date:any) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Issue Status",
      key: "issueStatus",
      dataIndex: "issueStatus",
      width: 100,
    },
    {
      title: "Handover Status",
      key: "handOverStatus",
      dataIndex: "handOverStatus",
      width: 100,
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Committed Stock"
      moduleKey="committedStockReport"
      columns={columns}
      filtersOption={{
        site: false,
        // contractor: true,
        dateRange: false,
      }}
      previousPath="/inventory-summary-reports-list"
      extra={{
        id: id,
        historyId: historyId,
      }}
    />
  );
}

export default CommittedStockReport