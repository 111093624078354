import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useLocation, useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import FormatDate from "../../masters/DateFormater";
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import { Icon } from "../../../components/Component";

function OrderDetailsByCategoryReport() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const name = location.state?.name;
  const columns: any = [
    {
      key: "orderId",
      title: "Order #",
      dataIndex: "orderId",
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
                state: {
                  previousPath: `/order-details-by-category-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width:150,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/order-details-by-category-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width:150,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width:200,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: string,record:any) =><span>{quantity} {record?.quantityUom}</span>
      
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Value Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Value With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ]
  return (
    <DefaultReportTemplate
      pageTitle={`${name} - Purchases, Detail Report`}
      moduleKey="purchaseByCategoryDetailReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/purchase-by-category-reports-list"
      extra={{
        id:id,
        tableScrollX:1700
      }}
    />
  );
}

export default OrderDetailsByCategoryReport;