import React, { useState, useEffect, FC } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Badge, Card, Spinner } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import sites from "../../../api/master/sites";
import FormInput from "../../form-components/InputComponent";
import FormDatePicker from "../../form-components/DatePicker";
import FormSelect from "../../form-components/SelectComponent";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import usersApis from "../../../api/master/users";
import FormatDate from "../DateFormater";
import moment from "moment";
import dayjs from "dayjs";
import FormAutoComplete from "../../form-components/SelectGeoLocation";
import { useSelector } from "react-redux";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Flex, Modal, Spin } from "antd";
interface SelectOptions {
  label: string;
  value: string;
}
const SitesViewDetails: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [submitBtnClick, setSubmitBtnClick] = useState(false);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { siteId } = useParams();
  const [data, setData] = useState<any>();
  const [modalGI, setGIModal] = useState(false);
  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split("-");
    const monthIndex: { [key: string]: number } = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };
    const monthNum = monthIndex[month];
    return new Date(Number(year), monthNum, Number(day));
  };

  const [formData, setFormData] = useState<any>({
    referenceID: "",
    entityName: "",
    name: "",
    country: "",
    state: "",
    city: "",
    district: "",
    pincode: "",
    plannedStartDate: "",
    plannedEndDate: "",
    currentStatus: "",
    siteAddressLine1: "",
    siteAddressLine2: "",
    actualCompletedDate: "",
    reasonForDelay: "",
    Blocks: [
      {
        blockName: "",
        squareFeet: "",
        startDate: null,
        endDate: null,
        currentStatus: "",
        blockID: "",
      },
    ],
  });
  const methods = useForm<any>({
    mode: "onChange",
    defaultValues: {
      referenceID: "",
      name: "",
      entityName: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      district: "",
      siteAddressLine1: "",
      siteAddressLine2: "",
      plannedStartDate: "",
      plannedEndDate: "",
      currentStatus: "",
      actualCompletedDate: "",
      reasonForDelay: "",
      Blocks: [
        {
          blockName: "",
          squareFeet: "",
          startDate: null,
          endDate: null,
          currentStatus: "",
        },
      ],
    },
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "Blocks",
  });
  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);
  const [docs, setDocs] = useState([]);
  const selectedCountry = watch("country");
  const siteDocumentWatch = watch("siteDocument");
  useEffect(() => {
    if (selectedCountry) {
      fetchStatesByCountry(selectedCountry);
    }
  }, [selectedCountry]);
  const CurrentStatusOptions = [
    { label: "Planned", value: "planned" },
    { label: "In Progress", value: "inprogress" },
    { label: "On Hold", value: "onhold" },
    { label: "Completed", value: "completed" },
  ];

  const currentStatusCompletedWatch = watch("currentStatus");
  const actualCompletedDateWatch = watch("actualCompletedDate");
  const plannedEndDateWatch = watch("plannedEndDate");
  const onSubmit = async (_data: any) => {
    setSubmitBtnClick(true);
    _data.plannedStartDate = moment(new Date(_data.plannedStartDate)).format(
      "YYYY-MM-DD"
    );
    _data.plannedEndDate = moment(new Date(_data.plannedEndDate)).format(
      "YYYY-MM-DD"
    );
    _data.Blocks = _data.Blocks.map((block: any) => ({
      ...block,
      startDate: moment(new Date(block.startDate)).format("YYYY-MM-DD"),
      endDate: moment(new Date(block.endDate)).format("YYYY-MM-DD"),
      actualCompletionDate: moment(new Date(block.actualCompletionDate)).format(
        "YYYY-MM-DD" 
      )
    }));
    // if (data.Blocks) {
    //   const { status, message } = await sites.updateBlockById(
    //     siteId,
    //     data.Blocks
    //   );
    // }

    const formData: any = new FormData();
    if (_data?.siteDocument) {
      const filesData = [];
      const files = [];
      const deletedFileData: any = [];
      if (_data.siteDocument[0]) {
        filesData.push({
          indexOfFileLocated: filesData.length,
          module: "site",
          purpose: "Site Document",
          level: "Header Level",
          uniqueId: "",
          action: "Create",
        });
        files.push(_data.siteDocument[0]);
        const previousDoc = docs?.filter(
          (document: any) => document.moduleFileType == "Site Document"
        );

        if (previousDoc) {
          previousDoc.forEach((doc: any) => {
            deletedFileData.push({
              docId: doc.docId,
            });
          });
        }
      }
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: filesData,
          deletedFileData: deletedFileData,
          updatedFileData: [],
        })
      );
      files.forEach((file) => {
        formData.append(`files`, file);
      });
    } else {
      formData.append("files", JSON.stringify([]));
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [],
          deletedFileData: [],
          updatedFileData: [],
        })
      );
    }
    delete _data.siteDocument;
    delete _data.siteAddress;
    console.log(_data);

    formData.append("siteData", JSON.stringify(_data));

    try {
      const { status, message } = await sites.updateSitesById(siteId, formData);
      if (status) {
        Swal.fire("Updated!", "Site has been updated", "success");
        setGIModal(false);
        fetchSiteDetails(siteId);
      } else {
        console.log("Error in form submission", message);
        Swal.fire("Failed!", `${message.join(", ")}`, "error");
      }
      setSubmitBtnClick(false);
    } catch (error: any) {
      Swal.fire("Failed!", error.response.data.message, "error");
      setSubmitBtnClick(false);
    }
  };

  const fetchCountrys = async (id?: string) => {
    try {
      const data = await usersApis.getCountrys();
      const countryOptions = data?.data?.map((country: any) => ({
        label: country.countryName,
        value: country.countryId.toString(),
      }));
      setCountries(countryOptions);
      if (id) {
        const country = countryOptions.find(
          (country: SelectOptions) => country.value === id.toString()
        );
        if (!country) {
          return;
        }
        setValue("country", id);
      }
    } catch (error) {}
  };

  const getDistricts = async (stateID: any, districtsID?: any) => {
    try {
      const districts = await sites.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
          if (districtsID) {
            setValue("district", districtsID);
          } else {
            setValue("district", districts.data[0].districtId.toString());
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const onStateChange = (e: any) => {
    console.log("e", e);
    getDistricts(e);
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
        setValue("state", state.value);
      }
    } catch (error) {}
  };
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  const selectedState = watch("state");

  useEffect(() => {
    viewChange();
    fetchCountrys();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = siteId;
    if (id !== undefined || null || "") {
      fetchSiteDetails(id);
    }
    if (selectedState) {
      getDistricts(selectedState, data?.district);
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [siteId]);

  const openEditModal = (modalEditBlockName: any) => {
    setModalEditBlockName(modalEditBlockName);
    setGIModal(true);
    // getCountrys()
  };

  const deleteAPI = async (id: any) => {
    try {
      const { status, message } = await sites.deleteBlockById(id);
      // fetchSiteDetails(siteId);
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };

  const handleRemove = (index: any, idPath: any) => {
    // Call delete API
    const values = getValues(); // Get the current form values
    const id: any = values?.Blocks?.[index];
    console.log("id", id.blockID);
    if (id.blockID) {
      deleteAPI(id.blockID).then(() => {
        // Remove from field array
        remove(index);
      });
    } else {
      remove(index);
    }
  };

  const fetchSiteDetails = async (id: any) => {
    try {
      const { data, status, message } = await sites.fetchSiteWithBlockById(id);
      if (status) {
        const newData = data;
        const {
          referenceID,
          name,
          entityName,
          country,
          state,
          district,
          siteAddressLine1,
          siteAddressLine2,
          plannedStartDate,
          plannedEndDate,
          currentStatus,
          Blocks,
          city,
          pincode,
          actualCompletedDate,
          reasonForDelay,
          docs,
          gstin,
          latitude,
          longitude,
        } = data;
        setDocs(docs);
        setData(newData); // Update data with fetched response
        let formatedBlocks = Blocks.map((block: any) => ({
          blockName: block.blockName,
          blockID: block.blockID,
          squareFeet: block.squareFeet,
          currentStatus: block.currentStatus,
          startDate: block.startDate ? dayjs(block.startDate) : null,
          endDate: block.endDate ? dayjs(block.endDate) : null,
          actualCompletionDate: block.actualCompletionDate ? dayjs(block.actualCompletionDate) : null
        }));
        if (Blocks.length === 0)
          formatedBlocks = [
            {
              blockName: "",
              squareFeet: 0,
              currentStatus: "inprogress",
              startDate: null,
              endDate: null,
              actualCompletionDate: null
            },
          ];
        setFormData({
          referenceID: newData.referenceID,
          name: newData.name,
          entityName: newData.entityName,
          country: newData.country,
          state: newData.state,
          city: newData.city,
          district: newData.district,
          pincode: newData.pincode,
          siteAddressLine1: newData.siteAddressLine1,
          siteAddressLine2: newData.siteAddressLine2,
          currentStatus: newData.currentStatus,
          plannedStartDate: newData.plannedStartDate,
          plannedEndDate: newData.plannedEndDate,
          Blocks: formatedBlocks,
          gstin: newData.gstin,
          // actualCompletedDate?: newData.actualCompletedDate,
          // reasonForDelay?: newData.reasonForDelay
        });
        setValue("referenceID", referenceID);
        setValue("name", name);
        setValue("entityName", entityName);
        setValue("country", country);
        setValue("state", state);
        getDistricts(state, district);
        setValue("city", city);
        // setValue("district", district);
        setValue("pincode", pincode);
        setValue("siteAddressLine1", siteAddressLine1);
        setValue("siteAddressLine2", siteAddressLine2);
        setValue("gstin", gstin);
        setValue("latitude", latitude);
        setValue("longitude", longitude);
        setValue("currentStatus", currentStatus);
        setValue("plannedStartDate", dayjs(newData.plannedStartDate));
        setValue("plannedEndDate", dayjs(newData.plannedEndDate));
        setValue("Blocks", formatedBlocks);
        // setValue("actualCompletedDate", new Date(actualCompletedDate));
        // setValue("reasonForDelay", reasonForDelay);
      }
      setIsLoading(false);
    } catch (err: any) {
      console.log(err);

      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (modalEditBlockName === "BlocksInformation") {
      // Reset form with initial data whenever modal is opened
      reset(formData);
    }
  }, [modalEditBlockName, formData, reset]);

  return (
    <React.Fragment>
      <Head title="Site Details - Regular"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Site Details -{" "}
                    <strong className="text-primary small">
                      {data["name"]}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Onboarded Date:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  {/* <Button
                    outline
                    className="bg-white d-none d-sm-inline-flex"
                    style={{ marginRight: "10px" }}
                    onClick={() => openEditModal()}
                  >
                    <Icon name="edit-alt-fill"></Icon>
                    <span>Edit</span>
                  </Button> */}
                  <Link to={`${process.env.PUBLIC_URL}/sites`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <Block>
                <Row className="gy-5">
                  <Col
                    lg="5"
                    sm={undefined}
                    md={undefined}
                    xxl={undefined}
                    size={undefined}
                    className={undefined}
                  >
                    <BlockHead>
                      <BlockBetween>
                        <BlockHeadContent>
                          <BlockTitle tag="h5">General Information</BlockTitle>
                          <p>Name, Address etc.</p>
                        </BlockHeadContent>
                        <BlockHeadContent>
                          {allowedActions?.edit ? (
                            <Button
                              outline
                              className="d-none d-sm-inline-flex"
                              style={{ marginRight: "10px" }}
                              onClick={() =>
                                openEditModal("GeneralInformation")
                              }
                            >
                              <Icon name="edit-alt-fill"></Icon>
                            </Button>
                          ) : (
                            <></>
                          )}
                        </BlockHeadContent>
                      </BlockBetween>
                    </BlockHead>
                    <Card className="card-bordered">
                      <ul className="data-list is-compact">
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Stie ID</div>
                            <div className="data-value">{data.referenceID}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Entity Name</div>
                            <div className="data-value">{data.entityName}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Stie Name</div>
                            <div className="data-value">{data.name}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">State</div>
                            <div className="data-value">{data?.stateName}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">District</div>
                            <div className="data-value">
                              {data?.districtName}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">City/Town</div>
                            <div className="data-value">{data.city}</div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Pincode</div>
                            <div className="data-value">{data.pincode}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Address Line 1</div>
                            <div className="data-value">
                              {data.siteAddressLine1}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Address Line 2</div>
                            <div className="data-value">
                              {data.siteAddressLine2}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">GSTIN</div>
                            <div className="data-value">{data.gstin}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Geo Location</div>
                            <div className="data-value">
                              <Button
                                outline
                                className="d-none d-sm-inline-flex m-0 p-0"
                                onClick={() => {
                                  if (data?.latitude && data?.longitude) {
                                    const googleMapsUrl = `https://www.google.com/maps?q=${data?.latitude},${data?.longitude}`;
                                    window.open(googleMapsUrl, "_blank");
                                  }
                                }}
                              >
                                View on Map{" "}
                                <Icon name="location" className="primary" />
                              </Button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Card>

                    <BlockHead>
                      <BlockBetween>
                        <BlockHeadContent>
                          <BlockTitle tag="h5">SITE STATUS</BlockTitle>
                          <p>Here is the current status of Site.</p>
                        </BlockHeadContent>
                        <BlockHeadContent>
                          {allowedActions?.edit ? (
                            <Button
                              outline
                              className="d-none d-sm-inline-flex"
                              style={{ marginRight: "10px" }}
                              onClick={() => openEditModal("SiteStatus")}
                            >
                              <Icon name="edit-alt-fill"></Icon>
                            </Button>
                          ) : (
                            <></>
                          )}
                        </BlockHeadContent>
                      </BlockBetween>
                    </BlockHead>

                    <Card className="card-bordered">
                      <ul className="data-list is-compact">
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Current Status</div>
                            <div
                              className="data-value"
                              style={{ textTransform: "uppercase" }}
                            >
                              <Badge
                                size="sm"
                                color={
                                  data.currentStatus === "completed"
                                    ? "outline-success"
                                    : data.currentStatus === "planned"
                                    ? "outline-info"
                                    : data.currentStatus === "inprogress"
                                    ? "outline-primary"
                                    : "outline-danger"
                                }
                                className="badge-dim"
                              >
                                {data.currentStatus === "inprogress"
                                  ? "In Progress"
                                  : data.currentStatus === "planned"
                                  ? "Planned"
                                  : data.currentStatus === "completed"
                                  ? "Completed"
                                  : "On Hold"}
                              </Badge>
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Planned Start Date</div>
                            <div className="data-value">
                              {dayjs(data.plannedStartDate).format(
                                "DD-MMM-YYYY"
                              )}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Planned End Date</div>
                            <div className="data-value">
                              {dayjs(data.plannedEndDate).format("DD-MMM-YYYY")}
                            </div>
                          </div>
                        </li>
                        {currentStatusCompletedWatch === "completed" && (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                Actual Completed Date
                              </div>
                              <div className="data-value">
                                {data?.actualCompletedDate &&
                                  FormatDate(data?.actualCompletedDate)}
                              </div>
                            </div>
                          </li>
                        )}
                        {data?.reasonForDelay &&
                          data.reasonForDelay.trim() &&
                          currentStatusCompletedWatch === "completed" && (
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">
                                  Reason For Delay
                                </div>
                                <div className="data-value">
                                  {data.reasonForDelay}
                                </div>
                              </div>
                            </li>
                          )}
                      </ul>
                    </Card>
                  </Col>

                  <Col lg="7">
                    <BlockHead>
                      <BlockBetween>
                        <BlockHeadContent>
                          <BlockTitle tag="h5">Blocks Information</BlockTitle>
                          <p>Basic Block Details etc.</p>
                        </BlockHeadContent>
                        <BlockHeadContent>
                          {allowedActions?.edit ? (
                            <Button
                              outline
                              className="d-none d-sm-inline-flex"
                              style={{ marginRight: "10px" }}
                              onClick={() => openEditModal("BlocksInformation")}
                            >
                              <Icon name="edit-alt-fill"></Icon>
                            </Button>
                          ) : (
                            <></>
                          )}
                        </BlockHeadContent>
                      </BlockBetween>
                    </BlockHead>

                    <Card className="card-bordered">
                      <div className="table-responsive mt-2 mb-2">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Block/Tower</th>
                              <th>Construction Area (in Sqft)</th>
                              <th>Planned Start Date</th>
                              <th>Planned End Date</th>
                              <th>Current Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.Blocks.length > 0 ? (
                              data.Blocks.map((itm: any) => {
                                return (
                                  <tr key={itm.blockName}>
                                    <td>{itm.blockName}</td>
                                    <td>{itm.squareFeet}</td>
                                    <td>
                                      {itm.startDate
                                        ? FormatDate(itm.startDate)
                                        : "Not Available"}
                                    </td>
                                    <td>
                                      {itm.endDate
                                        ? FormatDate(itm.endDate)
                                        : "Not Available"}
                                    </td>
                                    <td>{itm.currentStatus}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <>
                                <tr>
                                  <td colSpan={5} className="text-center">
                                    <span className="text-silent mt-5">
                                      No data found
                                    </span>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Card>
                    <BlockHead>
                      <BlockBetween>
                        <BlockHeadContent>
                          <BlockTitle tag="h5">Documents</BlockTitle>
                          <p>Here are the uploaded site documents.</p>
                        </BlockHeadContent>
                        <BlockHeadContent></BlockHeadContent>
                        <BlockHeadContent>
                          {allowedActions?.edit ? (
                            <Button
                              outline
                              className="d-none d-sm-inline-flex"
                              style={{ marginRight: "10px" }}
                              onClick={() => openEditModal("siteDocuments")}
                            >
                              <Icon name="upload"></Icon>
                            </Button>
                          ) : (
                            <></>
                          )}
                        </BlockHeadContent>
                      </BlockBetween>
                    </BlockHead>

                    <Card className="card-bordered">
                      <ul className="data-list is-compact">
                        {docs?.map((document: any) => {
                          return (
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">
                                  {document.moduleFileType}
                                </div>
                                <span className="data-value">
                                  <a
                                    href={document.filePath}
                                    target="_blank"
                                    style={{
                                      pointerEvents: "auto",
                                      cursor: "default",
                                    }}
                                  >
                                    {document.fileName}
                                  </a>
                                </span>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </Card>
                  </Col>
                </Row>
              </Block>
            )}

            <Modal
              open={modalGI}
              width={"90vw"}
              onCancel={() => setGIModal(false)}
              footer={null}
            >
              {modalEditBlockName === "GeneralInformation" && (
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                      <div className="nk-modal-head">
                        <h5 className="title">Edit General Information </h5>
                      </div>
                      <div className="nk-tnx-details mt-sm-3">
                        <Row className="gy-4">
                          <Col md="6">
                            <FormInput
                              name="referenceID"
                              label="Site ID"
                              required={true}
                              type="text"
                              pattern={/^[a-zA-Z0-9./_-]+$/}
                              maxLength={30}
                              placeholder="Enter Site ID"
                            />
                          </Col>
                          <Col md="6">
                            <FormInput
                              name="entityName"
                              label="Entity Name"
                              required={true}
                              type="text"
                              placeholder="Enter Entity"
                            />
                          </Col>
                          <Col md="6">
                            <FormInput
                              name="name"
                              label="Site Name"
                              required={true}
                              type="text"
                              minLength={2}
                              maxLength={30}
                              placeholder="Enter Site name"
                            />
                          </Col>
                          <Col md="6">
                            <FormSelect
                              name="country"
                              label="Country"
                              options={countries}
                              required={true}
                              placeholder="Select Country"
                            />
                          </Col>
                          <Col md="6">
                            <FormSelect
                              name="state"
                              label="State"
                              options={states}
                              required={true}
                              placeholder="Select State"
                              onChange={(ev) => onStateChange(ev)}
                            />
                          </Col>
                          <Col md="6">
                            <FormSelect
                              name="district"
                              label="District"
                              options={districtOptions}
                              required={true}
                              placeholder="Select District"
                            />
                          </Col>
                          <Col md="6">
                            <FormInput
                              name="city"
                              label="City/Town"
                              placeholder="Enter City/Town"
                              required={true}
                              type="text"
                            />
                          </Col>
                          <Col md="6">
                            <FormInput
                              name="pincode"
                              label="Zip/Pincode"
                              required={true}
                              type="number"
                              placeholder="Enter Zip or pin code"
                            />
                          </Col>
                          <Col md="6">
                            <FormInput
                              name="siteAddressLine1"
                              required={true}
                              label="Address Line 1"
                              placeholder="Enter Site Address"
                              type="text"
                            />
                          </Col>
                          <Col lg="6">
                            <FormInput
                              name="gstin"
                              label="GSTIN"
                              required={true}
                              type="text"
                              placeholder="Enter GSTIN"
                              pattern={
                                /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
                              }
                            />
                          </Col>
                          <Col md="6">
                            <FormInput
                              name="siteAddressLine2"
                              // required={true}
                              label="Address Line 2"
                              placeholder="Enter Address Line 2"
                              type="text"
                            />
                          </Col>
                          <Col md="12">
                            <FormAutoComplete
                              name="siteAddress"
                              label="Select Location"
                              required={true}
                              latitude={data?.latitude}
                              longitude={data?.longitude}
                              placeholder="Search for the site address"
                              onPlaceSelected={(place) => {
                                console.log("Selected place:", place);
                                if (place.geometry) {
                                  const { lat, lng }: any =
                                    place.geometry.location;
                                  setValue("latitude", lat());
                                  setValue("longitude", lng());
                                }
                              }}
                              tooltipText="Search and select the site address from Google Places."
                            />
                          </Col>

                          <Col size="12">
                            {submitBtnClick ? (
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button color="primary" type="button">
                                    <Spinner size="sm" color="light" />
                                  </Button>
                                </li>
                              </ul>
                            ) : (
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      reset(formData);
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              )}
              {modalEditBlockName === "siteDocuments" && (
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                      <div className="nk-modal-head">
                        <h5 className="title">Edit Site Documents</h5>
                      </div>
                      <div className="nk-tnx-details mt-sm-3">
                        <Row className="gy-4">
                          <Col md="4">
                            <FormInput
                              name="siteDocument"
                              label="Site Document"
                              required={true}
                              type="file"
                              placeholder="Enter Site Documents"
                            />
                            {siteDocumentWatch && (
                              <div>{siteDocumentWatch[0]?.name}</div>
                            )}
                          </Col>
                        </Row>
                        <div className="mt-3"></div>
                        <Row className="gy-4">
                          <Col md="12">
                            {submitBtnClick ? (
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button color="primary" type="button">
                                    <Spinner size="sm" color="light" />
                                  </Button>
                                </li>
                              </ul>
                            ) : (
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      reset(formData);
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              )}
              {modalEditBlockName === "SiteStatus" && (
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                      <div className="nk-modal-head">
                        <h5 className="title">Edit Site Status </h5>
                      </div>
                      <div className="nk-tnx-details mt-sm-3">
                        <Row className="gy-4">
                          <Col md="6">
                            <FormSelect
                              name="currentStatus"
                              label="Current Status"
                              options={CurrentStatusOptions}
                              required={true}
                              placeholder="-Select-"
                            />
                          </Col>
                          {currentStatusCompletedWatch === "completed" && (
                            <Col md="6">
                              <div className="form-group">
                                <FormDatePicker
                                  name="actualCompletedDate"
                                  label="Actual Completed Date"
                                  required={true}
                                  // onChange={(date: any) => {
                                  // setFormData({
                                  //   ...formData,
                                  //   actualCompletedDate: date,
                                  // });
                                  // resonforDelayTrigger(date);
                                  // }}
                                  // maxDate={new Date()}
                                  // minDate={
                                  //   new Date(new Date().getTime() + 86400000)
                                  // }
                                  // dateFormat="dd-MMM-yyyy"
                                />
                              </div>
                            </Col>
                          )}
                          <Col md="6">
                            <div className="form-group">
                              <FormDatePicker
                                name="plannedStartDate"
                                label="Planned Start Date"
                                required={true}
                                maxDate={new Date()}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <FormDatePicker
                                name="plannedEndDate"
                                label="Planned End Date"
                                required={true}
                                customValidation={{
                                  validate: (selectedDate: any) => {
                                    const startDate = watch("plannedStartDate");
                                    return (
                                      (startDate && selectedDate > startDate) ||
                                      "End date must be later than start date"
                                    );
                                  },
                                }}
                              />
                            </div>
                          </Col>
                          {/* {JSON.stringify(dayjs(actualCompletedDateWatch))}
                            {JSON.stringify(dayjs(plannedEndDateWatch))} */}
                          {dayjs(actualCompletedDateWatch) >
                            dayjs(plannedEndDateWatch) && (
                            <Col md="6">
                              <FormInput
                                label="Reason for Delay"
                                type="text"
                                name="reasonForDelay"
                                defaultValue={formData.reasonForDelay}
                                required
                              />
                            </Col>
                          )}
                          <Col md="12">
                            {submitBtnClick ? (
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button color="primary" type="button">
                                    <Spinner size="sm" color="light" />
                                  </Button>
                                </li>
                              </ul>
                            ) : (
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      //  reset(formData);
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              )}

              {modalEditBlockName === "BlocksInformation" && (
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                      <div className="nk-modal-head">
                        <h5 className="title">Edit Blocks Information </h5>
                      </div>
                      <div className="nk-tnx-details mt-sm-3">
                        <Row className="gy-4">
                          <Col md="12">
                            {fields.map((field, index) => (
                              <div
                                key={field.id}
                                className="row mb-3 d-flex align-items-end"
                              >
                                <div className="col">
                                  <FormInput
                                    name={`Blocks[${index}].blockName`}
                                    label="Block/Tower"
                                    required={true}
                                    type="text"
                                    placeholder="Block/Tower"
                                  />
                                </div>
                                <div className="col">
                                  <FormInput
                                    name={`Blocks[${index}].squareFeet`}
                                    label="Construction Area (in Sqft)"
                                    required={true}
                                    type="number"
                                    placeholder="Area (in Sq ft)"
                                    // pattern={/^\d*/}
                                    // onInput={(e: any) => {
                                    //   e.preventDefault(); // Prevent default behavior
                                    //   e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                    // }}
                                  />
                                </div>
                                <div className="col">
                                  <FormDatePicker
                                    name={`Blocks[${index}].startDate`}
                                    label="Planned Start Date"
                                    placeholder="Select Date"
                                    minDate={new Date(data.plannedStartDate)}
                                    maxDate={new Date(data.plannedEndDate)}
                                    required={true}
                                  />
                                </div>
                                <div className="col">
                                  <FormDatePicker
                                    name={`Blocks[${index}].endDate`}
                                    label="Planned End Date"
                                    required={true}
                                    placeholder="Select Date"
                                    minDate={new Date(data.plannedStartDate)}
                                    maxDate={new Date(data.plannedEndDate)}
                                    customValidation={{
                                      validate: (selectedDate) => {
                                        const startDate = watch(
                                          `Blocks[${index}].startDate` as any
                                        );
                                        return (
                                          new Date(selectedDate) >
                                            new Date(startDate) ||
                                          "End date must be greater than Start Date"
                                        );
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col">
                                  <FormSelect
                                    name={`Blocks[${index}].currentStatus`}
                                    label="Current Status"
                                    required={true}
                                    options={[
                                      { label: "Select", value: "" },
                                      { label: "Planned", value: "Planned" },
                                      {
                                        label: "In Progress",
                                        value: "In Progress",
                                      },
                                      { label: "On Hold", value: "On Hold" },
                                      {
                                        label: "Completed",
                                        value: "Completed",
                                      },
                                    ]}
                                    placeholder="Select"
                                  />
                                </div>
                                {watch(`Blocks[${index}].currentStatus`) ===
                                  "Completed" && (
                                  <div className="col">
                                    <FormDatePicker
                                      name={`Blocks[${index}].actualCompletionDate`}
                                      label="Actual Completion Date"
                                      required={true}
                                      placeholder="Select Date"
                                      maxDate={new Date()}
                                    />
                                  </div>
                                )}
                                <div className="col">
                                  <div style={{ marginTop: 30 }}>
                                    <a
                                      onClick={() => {
                                        handleRemove(
                                          index,
                                          `Blocks[${index}].id`
                                        );
                                      }}
                                      className="danger"
                                      style={{
                                        fontSize: 16,
                                        color: "red",
                                        marginTop: 14,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <MinusCircleOutlined /> Remove
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <Row className="mt-2">
                              <Col lg="12">
                                <a
                                  onClick={() => {
                                    append({
                                      currentStatus: "",
                                      squareFeet: "",
                                      startDate: null,
                                      endDate: null,
                                      blockName: "",
                                    });
                                  }}
                                  className="primary"
                                  style={{ fontSize: 16, cursor: "pointer" }}
                                >
                                  <PlusCircleOutlined /> Add More
                                </a>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="12">
                            {submitBtnClick ? (
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button color="primary" type="button">
                                    <Spinner size="sm" color="light" />
                                  </Button>
                                </li>
                              </ul>
                            ) : (
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      reset(formData);
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default SitesViewDetails;
