import React, { FC, useEffect, useState } from "react";
import { Row, Col, Button, Modal, ModalBody, Label } from "reactstrap";
import { Controller, useFormContext } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import FormSelect from "../form-components/SelectComponent";
import supplierApis from "../../api/master/supplier";
import FormDatePicker from "../form-components/DatePicker";
import dayjs from "dayjs";
import invoiceApis from "../../api/Invoice/invoice";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import { Popover } from "antd";
import misc from "../../api/misc/dropDowns";
import purchaseOrderCreation from "../../api/purchase-orders/pruchaseOrderCreation";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSequenceAccess } from "../../store/slices/misc/sequenceAccess";

const GeneralDetails: FC = (props: any) => {
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const dispatch: any = useDispatch();
  const { data: sequenceAccess } = useSelector(
    (state: any) => state.sequenceAccess
  );
  const [supplierOptions, setSupplierOptions] = useState<any[]>([]);
  const [invoiceOptions, setInvoiceOptions] = useState<any[]>([]);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<any[]>(
    []
  );
  const AllFormDetails = watch();
  const supplierIdWatch = watch("supplierId");
  const billingAddressId = watch("billingAddressId");
  const deliveryAddressId = watch("deliveryAddressId");

  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);
  useEffect(() => {
    if (supplierIdWatch) {
      fetchAllInvoicesForCreditDebitNote(supplierIdWatch);
      const _supplier = supplierOptions?.find(
        (item: any) => item.value == supplierIdWatch
      );
      if (!_supplier) return;
      setValue("supplierAddress", JSON.stringify(_supplier));
    }
  }, [supplierIdWatch]);

  useEffect(() => {
    if (billingAddressId) {
      const _address = deliveryAddressList?.find(
        (item: any) => item.id == billingAddressId
      );
      if (!_address) return;
      setValue("billingAddress", JSON.stringify(_address));
    }
  }, [billingAddressId]);

  useEffect(() => {
    if (deliveryAddressId) {
      const _address = deliveryAddressList?.find(
        (item: any) => item.id == deliveryAddressId
      );
      if (!_address) return;
      setValue("deliveryAddress", JSON.stringify(_address));
    }
  }, [deliveryAddressId]);

  const ShowAddressPopOver = ({ id, text, options, matchKey }: any) => {
    if (!id) {
      return <></>;
    }

    let address = options?.find((item: any) => item[matchKey] == id);

    if (!address) {
      return <></>;
    }
    return (
      <Popover
        content={
          <ul>
            <li>
              {address?.addressLine1} {address?.addressLine2}
            </li>
            <li>
              {address?.city},{address?.districtName}, {address?.stateName},
              {address?.countryName} - {address?.pincode}
            </li>
          </ul>
        }
        title={address?.deliveryLocationName}
      >
        <a>{text}</a>
      </Popover>
    );
  };
  const submitForm = (data: any) => {
    console.log("data", data);
    props?.next();
  };

  const fetchSupplier = async () => {
    try {
      const res =
        await purchaseOrderCreation.fetchBuyerLinkedSupplierListMethod();
      const { data, status } = res;
      if (status) {
        let supplierOptions = data?.map((item: any) => {
          const { sellerOrg } = item;
          const { addresses } = sellerOrg;
          // if (addresses?.length == 0) {
          //   return {
          //     label: sellerOrg.name,
          //     value: sellerOrg.orgId?.toString(),
          //     addressLine1: "",
          //     addressLine2: "",
          //     country: "",
          //     state: "",
          //     district: "",
          //     city: "",
          //     pincode: "",
          //   };
          // }

          const {
            addressLine1,
            addressLine2,
            countryInfo,
            stateInfo,
            districtInfo,
            city,
            pincode,
          } = addresses;
          return {
            label: sellerOrg.name,
            value: sellerOrg.orgId,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            country: countryInfo?.countryName,
            state: stateInfo?.stateName,
            district: districtInfo?.districtName,
            city: city,
            pincode: pincode,
          };
        });
        console.log("supplierOptions", supplierOptions);
        setSupplierOptions(supplierOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        let deliveryAddressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllInvoicesForCreditDebitNote = async (id: any) => {
    try {
      const res = await invoiceApis.fetchAllInvoicesForCreditDebitNote(id);
      const { data, status } = res;

      if (status) {
        let options = data.map((item: any) => ({
          label: `Invoice - ${item.invoiceNumber}`,
          value: item.invoiceNumber,
        }));
        setInvoiceOptions(options);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSupplier();
    fetchDeliveryAddress();
    const { noteType, supplierAddress } = AllFormDetails;
    if (noteType) {
      setValue("noteType", noteType);
    } else {
      setValue("noteType", "Credit");
    }
    if (supplierAddress) {
      setValue("supplierAddress", supplierAddress);
    }
  }, []);

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          {!sequenceAccess?.includes("Credit Note/Debit Note") ? (
            <Col lg="4">
              <FormInput
                name="noteId"
                label={`Note Number`}
                required={true}
                placeholder="Enter Note Number"
              />
            </Col>
          ) : (
            <></>
          )}
          <Col lg="8">
            <FormInput
              name="description"
              label="Description"
              required={true}
              type="text"
              minLength={2}
              maxLength={200}
              placeholder="Enter Description"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="supplierId"
              label={`Supplier/Vendor${
                supplierOptions.length == 0 ? "( Empty Supplier List)" : ""
              }`}
              options={supplierOptions?.map((item: any) => ({
                label: item.label,
                value: item.value,
              }))}
              required={true}
              placeholder="-Select-"
            />
          </Col>

          <Col lg="4">
            <FormSelect
              name="noteType"
              label="Note Type"
              options={[
                { label: "Debit", value: "Debit" },
                { label: "Credit", value: "Credit" },
              ]}
              required={true}
              placeholder="-Select-"
            />
          </Col>
          {/* <Col lg="4">
            <FormDatePicker
              name="dueDate"
              label="Due Date"
              minDate={new Date()}
            />
          </Col> */}
          <Col lg="4">
            <FormDatePicker
              name="noteDate"
              label="Credit Note Date"
              required={true}
              maxDate={new Date()}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="approvalRequired"
              label="Supplier Approval Required ?"
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              placeholder="Select"
              required={true}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="againstInvoice"
              label="Against Invoice"
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="-Select-"
              required={true}
            />
          </Col>

          <Col lg="4">
            <FormInput
              name="referenceNumber"
              label="Reference #"
              type="text"
              placeholder="Enter Reference Number"
            />
          </Col>
          {watch("againstInvoice") == "Yes" ? (
            <>
              <Col lg="4">
                <FormSelect
                  name="invoiceNumber"
                  label="Invoice"
                  options={invoiceOptions}
                  required={true}
                  placeholder="Select Invoice"
                />
              </Col>
            </>
          ) : (
            <>
              <Col lg="4">
                <FormSelect
                  name="billingAddressId"
                  label={`Billing Address${
                    deliveryAddressOptions.length == 0
                      ? "( Empty Billing Address List)"
                      : ""
                  }`}
                  options={deliveryAddressOptions}
                  popOverOptions={
                    <ShowAddressPopOver
                      id={billingAddressId}
                      matchKey="id"
                      options={deliveryAddressList}
                      text="View Selected Billing Address"
                    />
                  }
                  required={true}
                  placeholder="-Select-"
                />
              </Col>
              <Col lg="4">
                <FormSelect
                  name="deliveryAddressId"
                  label={`Delivery Address${
                    deliveryAddressOptions.length == 0
                      ? "( Empty Delivery Address List)"
                      : ""
                  }`}
                  options={deliveryAddressOptions}
                  popOverOptions={
                    <ShowAddressPopOver
                      id={deliveryAddressId}
                      matchKey="id"
                      options={deliveryAddressList}
                      text="View Selected Delivery Address"
                    />
                  }
                  required={true}
                  placeholder="-Select-"
                />
              </Col>
            </>
          )}
          <Col lg="8">
            <FormInput
              name="remarks"
              label="Remarks"
              type="text"
              placeholder="Enter Remarks"
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default GeneralDetails;
