import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Form, Row, Alert, Col, Button } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import { Icon, TooltipComponent } from "../../../components/Component";
import FormSelect from "../../form-components/SelectComponent";
import { Popover } from "antd";
import adminOrgApis from "../../../api/master/adminOrg";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import misc from "../../../api/misc/dropDowns";
import { nanoid } from "nanoid";
import materialsApis from "../../../api/master/materials";
import supplierApis from "../../../api/master/supplier";
interface SelectOptions {
  label: string;
  value: any;
}

const MaterialModal: FC<any> = ({
  modal,
  closeModal,
  append,
  update,
  formData,
  taxConfiguration,
  needByDateType,
  deliveryAddressType,
}) => {
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);

  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);

  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = methods;

  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const files = watch("attachment");

  useEffect(() => {
    if (files && files[0]?.size && files[0].size > 2097152) {
      setValue("attachment", null);
      setAlertInfo({
        type: "error",
        message: "File size should be less than 2 MB",
        errors: [],
      });
    }
  }, [files]);

  const materialCategory = watch("materialCategory");
  const deliveryAddressId = watch("deliveryAddressId");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAlertInfo({
        type: "",
        message: "",
        errors: [],
      });
    }, 2500);
    return () => clearTimeout(timeoutId);
  }, [alertInfo]);

  const fetchDeliveryAddress = async () => {
    try {
      const { data, status, message } =
        await deliveryAddressApis.fetchDeliveryAddress();

      if (status) {
        let deliveryAddressOptions = data.map((item: any) => {
          return {
            label: item.deliveryLocationName,
            value: item.id.toString(),
          };
        });
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      } else {
        console.log(message);
      }
    } catch (error) {}
  };

  const fetchUOMOPtions = async () => {
    try {
      const { data, status, message } = await adminOrgApis.fetchUom();
      if (status) {
        setUomOptions(
          data.map((item: any) => {
            return {
              label: item.uom_name,
              value: item.id.toString(),
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchMaterials = async () => {
    try {
      const { data, status } = await materialsApis.fetchProduct();
      if (status) {
        setMaterialCategoryOptions(data);
        const materialOption = data.map((item: any) => ({
          label: item.productName,
          value: item.materialID.toString(),
        }));
        setMaterialOptions(materialOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  useEffect(() => {
    if (modal && formData.item) {
      const {
        materialCategory,
        materialName,
        quantity,
        specification,
        uomId,
        remarks,
        description,
      } = formData.item;
      console.log(formData.item);
      setValue("materialCategory", materialCategory);
      setValue("materialName", materialName);
      setValue("quantity", quantity);
      setValue("specification",specification);
      setValue("uomId", uomId);
      setValue("remarks", remarks);
      setValue("description", description);
    }
  }, [modal]);

  const submitForm = (data: any) => {
    const {
      materialCategory,
      materialName,
      hsnCode,
      quantity,
      specification,
      remarks,
      description,
      uomId,
    } = data;
    const item = {
      materialCategory,
      materialName,
      hsnCode,
      quantity,
      specification,
      remarks,
      description,
    };

    const materialLabel = materialOptions.find(
      (item) => item.value == materialName
    )?.label;

    const categoryLabel = categoryOptions.find(
      (item) => item.value == materialCategory
    )?.label;
    const uomLabel = uomOptions.find((item) => item.value == uomId)?.label;

    if (formData.action == "add" || formData.index == null) {
      append({
        key: nanoid(),
        materialCategory,
        categoryLabel,
        materialName,
        materialLabel,
        hsnCode,
        quantity,
        specification,
        remarks,
        uomId,
        uomLabel,
        description,
      });
    } else {
      update(formData.index, {
        key: nanoid(),
        materialCategory,
        categoryLabel,
        materialName,
        materialLabel,
        hsnCode,
        quantity,
        specification,
        remarks,
        uomId,
        uomLabel,
        description,
      });
    }
    closeModal();
  };
  const ShowAddressPopOver = ({ id, text, options, matchKey }: any) => {
    if (!id) {
      return <></>;
    }
    let address = options?.find((item: any) => item[matchKey] == id);
    if (!address) {
      return <></>;
    }
    return (
      <Popover
        content={
          <ul>
            <li>
              {address?.addressLine1} {address?.addressLine2}
            </li>
            <li>
              {address?.city}, {address?.stateName}, {address?.countryName} -{" "}
              {address?.pincode}
            </li>
          </ul>
        }
        title={address?.deliveryLocationName}
      >
        <a>{text}</a>
      </Popover>
    );
  };
  useEffect(() => {
    fetchCategorys();
    fetchMaterials();
    fetchUOMOPtions();
    fetchDeliveryAddress();
  }, []);

  return (
    <Modal isOpen={modal} toggle={closeModal} size="xl">
      <ModalBody>
        <h5 className="title">
          {formData.action == "add" || formData.index == null
            ? "Add New Line"
            : "Update Line"}
        </h5>
        <div style={{ marginTop: "15px" }}></div>
        {alertInfo.message && (
          <div className="mb-3 mt-1">
            <Alert
              color={alertInfo.type === "error" ? "danger" : "success"}
              className="alert-icon"
            >
              <strong>{alertInfo.message}</strong>
              <Icon
                name={
                  alertInfo.type === "error" ? "alert-circle" : "check-circle"
                }
              />
              {alertInfo.errors.length > 0 ? (
                <ul>
                  {alertInfo.errors.map((err: any, index: any) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </Alert>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitForm)}>
            <Row className="mt-12">
              <div className="col-md-6">
                <FormSelect
                  name="materialCategory"
                  label="Material Category"
                  required={true}
                  options={categoryOptions}
                  placeholder="Select Material Category"
                />
              </div>
              <div className="col-md-6">
                <FormSelect
                  name="materialName"
                  label="Material Name"
                  required={true}
                  options={materialCategoryOptions
                    ?.filter((item: any) => item.category == materialCategory)
                    ?.map((item: any) => ({
                      label: item.productName,
                      value: item.materialID,
                    }))}
                  placeholder="Select Material Name"
                />
              </div>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <div className="col-md-3">
                <FormInput
                  type="number"
                  min={1}
                  required={true}
                  name="quantity"
                  label="Required Quantity"
                  placeholder="Enter Quantity"
                />
              </div>
              <div className="col-md-3">
                <FormSelect
                  name="uomId"
                  label="UOM"
                  required={true}
                  options={uomOptions}
                  placeholder="Units"
                />
              </div>
              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="specification"
                  label="Specification"
                  placeholder="Enter Specification"
                />
              </div>
            </Row>
            <Row className="mt-4">
              <div className="col-md-6">
                <FormInput
                  type="text"
                  required={false}
                  min={1}
                  name="remarks"
                  label="Remarks (if any)"
                  placeholder="Enter Remarks"
                />
              </div>
              {/* <div className="col-md-12">
                                <FormTextArea
                                    name="description"
                                    required={false}
                                    label="Description (if any)"
                                    placeholder="Enter Description"
                                    minLength={5}
                                    maxLength={200}
                                />
                            </div> */}
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  {formData.action === "edit" && formData.index != null && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Update
                      </Button>
                    </li>
                  )}
                  {(formData.action == "add" || formData.index == null) && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Save
                      </Button>
                    </li>
                  )}
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default MaterialModal;
