import React, { useEffect, useState, useCallback } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormTextArea from "../../form-components/TextAreaComponent";
import departments from "../../../api/master/departments";
import Swal from "sweetalert2";
import rolesApi from "../../../api/master/roles";
interface SelectOptions {
  label: string;
  value: string;
}
const GeneralInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const methods = useForm({
    mode: "onChange",
  });

  const { watch, setValue, trigger, reset } = methods;
  const submitForm = (data: any) => {
    console.log("General Info Data:", data);
    props.next();
  };

  const [departmentData, setDepartmentData] = useState<SelectOptions[]>([]);
  const [showaccessebility, setShowaccessebility] = useState(false);

  const fetchDept = async () => {
    try {
      const { data, status, message } = await departments.fetchDeptData();
      if (status) {
        setDepartmentData(
          data.map((obj: any) => ({
            label: obj.deptName,
            value: obj.deptID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };
  useEffect(() => {
    fetchDept();
  }, []);
  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row>
          <Col lg="4">
            <FormInput
              name="referenceID"
              label="Role ID"
              required={true}
              type="text"
              pattern={/^[a-zA-Z0-9./_-]+$/}
              maxLength={10}
              placeholder="Enter Role ID"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="deptID"
              label="Department Name"
              required={true}
              options={departmentData}
              placeholder="Select Department Name"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="roleName"
              label="Role Name"
              required={true}
              type="text"
              minLength={2}
              // maxLength={30}
              placeholder="Enter Role Name"
              pattern={/^[A-Za-z\s]+$/}
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ""); // Removes invalid characters
              }}
            />
          </Col>
          <Col lg="12">
            <FormTextArea
              name="description"
              label="Description"
              required={true}
              maxLength={50}
              placeholder="Enter Role Description"
            />
          </Col>
          <div style={{ marginTop: "20px" }}></div>
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default React.memo(GeneralInformation);
