import React, { useEffect, FC } from "react";
import { Modal, ModalBody, Form, Row, Alert } from "reactstrap";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Col } from "reactstrap";
import { useForm } from "react-hook-form";

const AddModal: FC<any> = ({
  modal,
  closeModal,
  onSubmit,
  formData,
  setFormData,
  filterStatus,
  alertInfo,
}) => {
  useEffect(() => {
    reset(formData);
  }, [formData]);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Modal
      isOpen={modal}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="lg"
      backdrop="static"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Add Department</h5>
          {alertInfo.message && (
            <div className="mb-3 mt-1">
              <Alert
                color={alertInfo.type === "error" ? "danger" : "success"}
                className="alert-icon"
              >
                <strong>{alertInfo.message}</strong>
                <Icon
                  name={
                    alertInfo.type === "error" ? "alert-circle" : "check-circle"
                  }
                />
                {alertInfo.errors.length > 0 ? (
                  <ul>
                    {alertInfo.errors.map((err: any, index: any) => (
                      <li key={index}>{err}</li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </Alert>
            </div>
          )}
          <div className="mt-4">
            <Form
              className="row gy-4"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Col md="4">
                <div className="form-group">
                  <label className="form-label">
                  Department ID<span className="mandatory">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("referenceID", {
                      required: "This field is required",
                    })}
                    value={formData.referenceID}
                    onChange={(e) =>
                      setFormData({ ...formData, referenceID: e.target.value })
                    }
                    placeholder="Enter Department ID"
                  />
                  {errors.referenceID &&
                    typeof errors.referenceID.message === "string" && (
                      <span className="invalid">
                        {errors.referenceID.message}
                      </span>
                    )}
                </div>
              </Col>
              <Col md="4">
                <div className="form-group">
                  <label className="form-label">
                    Department Name<span className="mandatory">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("deptName", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "Invalid format",
                      },
                    })}
                    value={formData.deptName}
                    onChange={(e) =>
                      setFormData({ ...formData, deptName: e.target.value })
                    }
                    onInput={(e: any) => {
                      e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ""); // Removes invalid characters
                    }}
                    placeholder="Enter Department Name"
                  />
                  {errors.deptName &&
                    typeof errors.deptName.message === "string" && (
                      <span className="invalid">{errors.deptName.message}</span>
                    )}
                </div>
              </Col>
              <Col md="4">
                <div className="form-group">
                  <label className="form-label">Team Count</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("teamCount", {
                      // required: "This field is required",
                    })}
                    value={formData.teamCount}
                    onChange={(e) =>
                      setFormData({ ...formData, teamCount: e.target.value })
                    }
                    placeholder="Team Count"
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">
                    Description<span className="mandatory">*</span>
                  </label>
                  <textarea
                    className="form-control form-control-sm"
                    id="fv-message"
                    {...register("description", {
                      required: "This field is required",
                    })}
                    value={formData.description}
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                    placeholder="Write your description"
                  />
                  {errors.description &&
                    typeof errors.description.message === "string" && (
                      <span className="invalid">
                        {errors.description.message}
                      </span>
                    )}
                </div>
              </Col>
              <Row className="mt-4">
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        {/* <Icon name="check" /> */}
                        <span>Submit</span>
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          closeModal();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default AddModal;
