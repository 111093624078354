import React from "react";
import { useNavigate } from "react-router";
import FormatDate from "../../masters/DateFormater";
import { TooltipComponent } from "../../../components/Component";
import DefaultReportTemplate from "../defaultReportTemplate";
import { formatCurrency } from "../../../utils/Utils";

function InvoiceBySupplierReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      key: "orderId",
      title: "Order #",
      dataIndex: "orderId",
      width: 200,
      // fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
                state: { previousPath: "/invoice-by-supplier-reports-list" },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 200,
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: { previousPath: "/invoice-by-supplier-reports-list" },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 150,
      render: (date: any) => <span>{FormatDate(date) || "N/A"}</span>,
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      width: 200,
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 150,
      render: (date: any) => <span>{FormatDate(date) || "N/A"}</span>,
    },
    {
      title: "GRN #",
      dataIndex: "deliveryNoteNumber",
      key: "deliveryNoteNumber",
      width: 180,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/grn-preview-report/${record?.deliveryNoteId}`, {
                state: { previousPath: "/invoice-by-supplier-reports-list" },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "GRN Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      width: 150,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Payment Due Date",
      dataIndex: "paymentDueDate",
      key: "paymentDueDate",
      width: 150,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Payment Days",
      dataIndex: "aging",
      key: "aging",
      width: 160,
      render: (text: any, record: any) => {
        //  "dueType": "overDue",
        const isHighlighted = record?.dueType == "overDue";
        let placeholder = "";
        if (record?.dueType == "overDue") {
          placeholder = "OVERDUE BY " + Number(text) + " DAYS";
        } else {
          if (record?.dueType == "Paid") {
            placeholder = "PAID";
          } else {
            if (text == 0) {
              placeholder = "DUE TODAY";
            } else {
              placeholder = "DUE IN " + Number(text) + " DAYS";
            }
          }
        }
        return (
          <div
            className="align-left"
            style={{
              color: isHighlighted ? "lightcoral" : "green",
              backgroundColor: isHighlighted ? "white" : "inherit",
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {placeholder}
          </div>
        );
      },
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`supplierName-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`supplierName-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Invoice Value with Tax",
      dataIndex: "amount",
      key: "amount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`desc-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`desc-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    // {
    //   title: "Site ID",
    //   dataIndex: "siteId",
    //   key: "siteId",
    //   width: 100,
    //   sorter: (a: any, b: any) => a.siteId - b.siteId,
    // },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Invoice Status",
      dataIndex: "invoiceStatus",
      key: "invoiceStatus",
      width: 100,
    },
    // {
    //   title: "Currency Code",
    //   dataIndex: "currencyCode",
    //   key: "currencyCode",
    //   width: 100,
    //   sorter: (a: any, b: any) =>
    //     a.currencyCode.localeCompare(b.currencyCode),
    // },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Invoice By Supplier"
      moduleKey="invoiceBySupplierReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
        supplier: true,
      }}
      previousPath="/view-purchase-reports"
    />
  );
}

export default InvoiceBySupplierReport;
