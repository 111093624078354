import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button, Badge } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
  Tooltip,
  Drawer,
  Modal,
  Form,
  Popover,
  Timeline,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import {
  SearchOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";
import DispatchAside from "./paymentsNav";
import paymentApis from "../../api/payments/payments";
import { formatCurrency, formatCurrency2 } from "../../utils/Utils";
import usersApis from "../../api/master/users";
import invoiceApis from "../../api/Invoice/invoice";
import { render } from "@testing-library/react";
import { title } from "process";
import dayjs from "dayjs";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const ViewPayments: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [widgetData, setWidgetData] = useState<WidgetData[]>([
    {
      title: "Outstanding",
      count: 0,
      progress: 0,
      color: "#OO65BD",
    },
    {
      title: "Overdue By 7 Days",
      count: 0,
      progress: 0,
      color: "#20639B",
    },
    {
      title: "Overdue By 15 Days",
      count: 0,
      progress: 0,
      color: "#FA991C",
    },
    {
      title: "Overdue By Month",
      count: 0,
      progress: 0,
      color: "#F6D55C",
    },
    {
      title: "Overdue By >1 Month",
      count: 0,
      progress: 0,
      color: "#OOCC99",
    },
  ]);
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState<any>(null);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [approverIds, setApproverIds] = useState<any>([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    fetchCurrentUser();
    fetchData();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const columns: any = [
    {
      title: "#",
      key: "#",
      width: 50,
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 200,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },

    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 180,
    },
    {
      title: "Gross Amount",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <div style={{ textAlign: "right" }}>
            <span>
              {formatCurrency(Number(text), "INR")}
              {/* <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip> */}
            </span>
          </div>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 150,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 150,
    },
    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      key: "invoiceType",
      width: 100,
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 180,
      fixed: "right",
       render: (text: any, record: any) => {
        const paymentStatus:any={
          "FullyPaid":"FULLY PAID",
          "PartiallyPaid":"PARTIALLY PAID",
        }
        if (text == "Pending") {
          const { days } = record?.due;
          if (days <= 0) {
            return <p style={{ color: "blue",fontSize:"14px" }} className="tb-status"> DUE IN {days*-1} DAYS</p>;
          }
          return (
            <p className="text-danger"> OVERDUE BY {record?.due?.days} DAYS</p>
          );
        }
        return <p style={{ color: "green",fontSize:"14px" }} className="tb-status">{paymentStatus[text]||text}</p>;
      }, 
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 60,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setViewDrawer(true);
              fetchPaymentDetails(record?.invoiceNumber);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  const fetchPaymentDetails = async (invoiceNumber: any) => {
    try {
      const res = await paymentApis.fetchAllInvoicePaymentLines(invoiceNumber);
      console.log(res);

      const { data, status, message } = res;
      if (status) {
        setDrawerData(data);

        setApproverIds(
          data?.paymentApprovals
            ?.filter((item: any) => item?.status == "Pending")
            ?.map((item: any) => item?.userId)
        );
      } else {
        setViewDrawer(false);
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const ApprovalTimelineContent = (approvalsData: any) => {
    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No Approvals</Badge>;
    }

    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "Approved";
              const isRejected = approval.status == "Rejected";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                  {approval?.approvalDate && (
                    <div className="designation">
                      {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Badge color="outline-success">View</Badge>
        </span>
      </Popover>
    );
  };

  const drawerColumns: any = [
    {
      title: "Payment #",
      key: "paymentId",
      width: 200,
      fixed: "left",
      render: (text: any, record: any) => (
        <div className="align-left">
          <span>{record?.payment?.paymentId}</span>
        </div>
      ),
    },
    {
      title: "Payment Date",
      key: "paymentDate",
      width: 150,
      render: (date: any, record: any) => (
        <span>{FormatDate(record?.payment?.paymentDate)}</span>
      ),
    },
    {
      title: "Payment Mode",
      key: "paymentType",
      width: 120,
      render: (text: any, record: any) => (
        <span>{record?.payment?.paymentMode}</span>
      ),
    },
    {
      title: "Transaction #",
      key: "transactionNumber",
      width: 120,
      render: (text: any, record: any) => {
        const number = record?.payment?.transactionNumber;
        return number ? (
          <span>
            {number?.substring(0, 8)}...
            {number?.length > 8 ? (
              <Tooltip title={number}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            ) : (
              <></>
            )}
          </span>
        ) : (
          <span>--</span>
        );
      },
    },
    {
      title: "Payable Amount",
      key: "payableAmount",
      width: 100,
      render: (_text: any, record: any) => {
        const text = record?.payment?.payableAmount;
        return (
          <div style={{ textAlign: "right" }}>
            <span>
              {formatCurrency(Number(text), "INR")}
              {/* <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip> */}
            </span>
          </div>
        );
      },
    },
    {
      title: "Track Approvals",
      key: "Approvals",
      width: 100,
      render: (text: any, record: any) => {
        const paymentApprovals = record?.payment?.paymentApprovals;
        return <span>{ApprovalTimelineContent(paymentApprovals)}</span>;
      },
    },
  ];

  const fetchData = async () => {
    try {
      const res = await invoiceApis.fetchAllPaidInvoices();
      const { data, status, message } = res;
      if (status) {
        setOriginalData(data);
        setFilteredData(data);
        setWidgetData((widgetArray: any) => {
          return widgetArray.map((widget: any) => {
            let statusCount = 0;
            data.forEach((item: any) => {
              if (item?.paymentStatus !== "Pending") {
                return;
              }
              switch (widget?.title) {
                case "Outstanding":
                  if (item?.due?.days <= 0) {
                    statusCount++;
                  }
                  break;
                case "Overdue By 7 Days":
                  if (item?.due?.days > 0 && item?.due?.days <= 7) {
                    statusCount++;
                  }
                  break;
                case "Overdue By 15 Days":
                  if (item?.due?.days > 7 && item?.due?.days <= 15) {
                    statusCount++;
                  }
                  break;
                case "Overdue By Month":
                  if (item?.due?.days > 15 && item?.due?.days <= 30) {
                    statusCount++;
                  }
                  break;
                case "Overdue By >1 Month":
                  if (item?.due?.days > 30 && item?.due?.days <= 60) {
                    statusCount++;
                  }
                  break;
                default:
              }
            });
            return {
              ...widget,
              count: statusCount,
              progress: statusCount
                ? ((statusCount / data?.length) * 100).toFixed(0)
                : 0,
            };
          });
        });
      } else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const fetchCurrentUser = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        setCurrentUserId(data?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const disapprovePayment = async (reason: any) => {
    try {
      const res = await paymentApis.disapprovePayment(
        drawerData?.paymentNumber,
        reason
      );
      const { status, message } = res;
      if (status) {
        Swal.fire("Success", message, "success");
        fetchData();
      }
    } catch (error) {}
  };

  return (
    <>
      <Head title="View All Payments" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <DispatchAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">All Payments</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                {originalData.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Empty description="No Pending Payment Approvals" />
                  </div>
                ) : (
                  <div>
                    <Block>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                          width: "100%",
                        }}
                      >
                        {widgetData.map((widget, index) => (
                          <div
                            key={index}
                            style={{
                              border: "1px solid #dbdfea",
                              padding: "15px",
                              borderRadius: "8px",
                              width: "calc(20% - 10px)", // Ensure widgets are 20% of the width
                              minWidth: "150px", // Ensure a minimum width for smaller screens
                              marginBottom: "10px",
                            }}
                          >
                            <div style={{ marginBottom: "20px" }}>
                              <h6 style={{ margin: 0 }}>{widget.title}</h6>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <Progress
                                  type="circle"
                                  percent={
                                    Number(widget?.progress)
                                      ?.toFixed(0)
                                      ?.toString() as any
                                  }
                                  width={50}
                                  strokeColor={widget.color}
                                  format={(percent) => `${percent}%`}
                                />
                              </div>
                              <div style={{ flex: 1, textAlign: "center" }}>
                                <p style={{ fontSize: "24px", margin: 0 }}>
                                  {widget.count}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Block>
                    {filteredData.length == 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                        }}
                      >
                        <Empty description="No Data for selected filters" />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: screenSize - 330 + "px",
                          minHeight: "200px",
                          marginTop: "20px",
                        }}
                      >
                        <Table
                          className="customTable"
                          columns={columns.map((item: any) => ({
                            ...item,
                          }))}
                          dataSource={filteredData}
                          pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "30", "50"],
                          }}
                          bordered
                          size="middle"
                          scroll={{ x: 1400 }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
      </Content>
      <Drawer
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong>Payment's Summary </strong>
          </div>
        }
        placement="right"
        onClose={() => setViewDrawer(false)}
        open={viewDrawer}
        width="85vw"
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{ body: { paddingBottom: 80 } }}
      >
        {drawerData ? (
          <>
            <div className="table-responsive poTable">
              <div
                style={{
                  width: screenSize - 300 + "px",
                  margin: "0px auto 15px auto",
                }}
              >
                <Table
                  className="customTable"
                  columns={drawerColumns}
                  dataSource={drawerData}
                  scroll={{ x: 1000 }}
                  bordered
                  size="middle"
                  pagination={false}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Flex align="center" gap="middle" className="pageLoader">
              <Spin size="large" className="m-auto" />
            </Flex>
          </>
        )}
      </Drawer>
      <DisapprovePayment
        isModalVisible={showRejectModal}
        setIsModalVisible={setShowRejectModal}
        reject={disapprovePayment}
      />
    </>
  );
};

const DisapprovePayment = ({
  isModalVisible,
  setIsModalVisible,
  reject,
}: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        reject(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="Reason for Rejection"
      open={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="rejectForm"
        requiredMark={false}
      >
        <Form.Item
          name="reason"
          label={undefined}
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Submit
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </Modal>
  );
};

export default ViewPayments;
