import React, { useEffect, useState, useCallback } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Row, Col } from "reactstrap";
import FormSelect from "../../form-components/SelectComponent";
import usersApis from "../../../api/master/users";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
import sites from "../../../api/master/sites";
import Swal from "sweetalert2";
interface SelectOptions {
  label: string;
  value: string;
}
const ContractorInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);
  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [workingSites, setWorkingSites] = useState<any>([]);

  const countryWatch = watch("country");
  const stateWatch = watch("state");
  useEffect(() => {
    fetchStatesByCountry(countryWatch);
  }, [countryWatch]);
  useEffect(() => {
    getDistricts(stateWatch);
  }, [stateWatch]);
  useEffect(() => {
    getCountries();
    fetchWorkingSites();
    fetchStatesByCountry("1");
  }, []);

  const onStateChange = (e: any) => {
    getDistricts(e);
  };

  const fetchWorkingSites = async (selectedOptions: SelectOptions[] = []) => {
    try {
      const { data, status, message } = await sites.fetchSites();
      if (status) {
        setWorkingSites(
          data.map((item: any) => ({
            label: item.name,
            value: item.siteID?.toString(),
          }))
        );
        // if (selectedOptions.length > 0) {
        //   setValue("siteAccessibility", selectedOptions);
        // }
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };

  const getCountries = async () => {
    try {
      const _countries = await usersApis.getCountrys();
      if (_countries.status) {
        if (_countries.data.length > 0) {
          const transformedData = _countries.data.map((item: any) => ({
            value: item.countryId.toString(),
            label: item.countryName,
          }));
          setCountries(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const fetchStatesByCountry = async (countryId: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
    } catch (error) {}
  };
  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const submitForm = (data: any) => {
    console.log("General Info Data:", data);
    props.next();
  };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <Col md="3">
          <FormInput
            name="referenceID"
            label="Contractor ID"
            type="text"
            pattern={/^[a-zA-Z0-9./_-]+$/}
            required={true}
            // maxLength={10}
            placeholder="Enter Contractor ID"
          />
        </Col>
        <Col lg="3">
          <FormSelect
            name="contractorType"
            label="Contractor Type"
            required={true}
            options={[
              { label: "General Contractor", value: "General Contractor" },
              { label: "Sub Contractor", value: "Sub Contractor" },
              { label: "Self Construction", value: "Self Construction" },
            ]}
            placeholder="Enter Contractor Terms"
          />
        </Col>
        <Col lg="3">
          <FormInput
            name="companyName"
            label="Contractor Name"
            required={true}
            placeholder="Enter Contractor Name"
            pattern={/^[A-Za-z\s]+$/}
            onInput={(e: any) => {
              e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ""); // Removes invalid characters
            }}
          />
        </Col>
        {/* <Col md="3">
          <FormInput
            name="companyName"
            label="Company Name"
            required={true}
            type="text"
            maxLength={50}
            placeholder="Enter Company Name"
            // disabled={action === "view"}
          />
        </Col> */}
        <Col md="3">
          <FormInput
            name="contactPerson"
            // disabled={action === "view"}
            label="Contact Person"
            required={true}
            type="text"
            maxLength={50}
            placeholder="Enter Contact Person"
            pattern={/^[A-Za-z\s]+$/}
            onInput={(e: any) => {
              e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ""); // Removes invalid characters
            }}
          />
        </Col>
        <Col md="3">
          <FormMultiSelect
            name="siteAccessibility"
            label="Site Accessibility"
            required={true}
            options={workingSites}
            placeholder="Select Site Accessibility"
          />
        </Col>
        <Col md="3">
          <FormInput
            name="email"
            // disabled={action === "view"}
            required={true}
            label="Email"
            type="email"
            placeholder="Enter Email"
            pattern={/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/}
          />
        </Col>
        <Col md="3">
          <FormInput
            name="phoneNumber"
            label="Phone Number"
            // disabled={action === "view"}
            required={true}
            type="text"
            pattern={/^\d+$/} // Allows only digits
            onInput={(e: any) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
            }}
            placeholder="Enter Phone Number"
          />
        </Col>
        {/* <Col md="3">
          <FormInput
            name="mailingAddressLine1"
            label="Business Address"
            // disabled={action === "view"}
            required={true}
            type="text"
            placeholder="Enter Business Address"
          />
        </Col> */}
        <Col lg="3">
          <FormSelect
            name="country"
            label="Country"
            options={countries}
            required={true}
            placeholder="-Select-"
          />
        </Col>
        <Col md="3">
          <FormSelect
            name="state"
            label="State"
            required={true}
            options={states}
            placeholder="Select State"
          />
        </Col>
        <Col md="3">
          <FormSelect
            name="district"
            label="District"
            options={districtOptions}
            required={true}
            placeholder="Select District"
          />
        </Col>
        <Col md="3">
          <FormInput
            name="city"
            label="City"
            required={true}
            // disabled={action === "view"}
            placeholder="Enter City"
            type="text"
          />
        </Col>
        <Col lg="3">
          <FormInput
            name="area"
            // disabled={action === "view"}
            label="Area"
            required={true}
            placeholder="Enter Area"
          />
        </Col>
        <Col md="3">
          <FormInput
            name="mailingAddressLine1"
            // disabled={action === "view"}
            label="Address Line 1"
            required={true}
            placeholder="Enter Area"
          />
        </Col>
        <Col md="3">
          <FormInput
            name="pincode"
            // disabled={action === "view"}
            label="Zip/Pincode"
            required={true}
            type="text"
            pattern={/^\d+$/} // Allows only digits
            onInput={(e: any) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
            }}
            placeholder="Enter Zip or pin code"
          />
        </Col>
        <Col md="3">
          <FormInput
            name="yearsInBusiness"
            // disabled={action === "view"}
            label="Years in Business"
            type="text"
            placeholder="Enter Years in Business"
            pattern={/^\d+$/} // Allows only digits
            onInput={(e: any) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
            }}
          />
        </Col>
        <Col md="3">
          <FormInput
            name="references"
            label="References"
            type="text"
            // disabled={action === "view"}
            placeholder="Enter References"
          />
        </Col>
      </Row>
      <Row className="gy-4 mt-1">
        <Col>
          <Button color="primary" type="submit">
            Next
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default React.memo(ContractorInformation);
